export function parseProfileData(data: any) {
  const parsedData = {
    ...(data?.first_name && {
      first_name: data?.first_name,
    }),
    ...(data?.last_name && {
      last_name: data?.last_name,
    }),
    ...(data?.newpassword && {
      password: data?.newpassword,
    }),
    ...(data?.email && {
      email: data?.email,
    }),
  };

  return parsedData;
}
