import { Column } from "react-table";
import { CustomHeader } from "./CustomHeader";
import { useListingColumns } from "../../core/_models";
import ColumnActionCell from "./ColumnActionCell";

const usersColumns: Array<Column<useListingColumns>> = [
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="First Name"
        className="min-w-150px"
      />
    ),
    accessor: "first_name",
    Cell: ({ row }) => {
      // @ts-ignore: Unreachable code error
      return (
        <div className="row">
          <div className="col-md-12">
            <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
              {row?.original?.first_name}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Last Name"
        className="min-w-150px"
      />
    ),
    accessor: "last_name",
    Cell: ({ row }) => {
      // @ts-ignore: Unreachable code error
      return (
        <div className="row">
          <div className="col-md-10">
            <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
              {row?.original?.last_name}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title="Email" className="min-w-150px" />
    ),
    id: "email",
    Cell: ({ row }) => {
      // @ts-ignore: Unreachable code error
      return (
        <div className="row">
          <div className="col-md-10">
            <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
              {row?.original?.email || "-"}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Action"
        className="min-w-50px"
        isSortable={false}
      />
    ),
    accessor: "eAction",
    Cell: ({ row }) => {
      return (
        <div className="row">
          <div className="col-md-10">
            <ColumnActionCell row={row} />
          </div>
        </div>
      );
    },
  },
];

export { usersColumns };
