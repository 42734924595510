import axios from "axios";

const NODE_URL = process.env.REACT_APP_API_URL;

const getApiKeyList = (): Promise<any> => {
    return axios.get(`${NODE_URL}api/get-api-keys`).then((response) => {
        return response.data
    })
}

const addEditApiKey = (data: any): Promise<any> => {
    return axios.post(`${NODE_URL}api/add-api-key`, data).then((response) => {
        return response.data
    })
}

const deleteApiKey = (data: any): Promise<any> => {
    return axios.post(`${NODE_URL}api/delete-api-key`, data).then((response) => {
        return response.data
    })

}
export {
    getApiKeyList,
    deleteApiKey,
    addEditApiKey
}