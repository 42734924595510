/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";

// import { Link } from 'react-router-dom'
import { CopyToClipboard } from "react-copy-to-clipboard";

import * as auth from "../../../../app/modules/auth/redux/AuthRedux";
import { useDispatch } from "react-redux";
import { KTSVG } from "../../../helpers/components/KTSVG";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useQuery } from "react-query";
import { getProfileData } from "../../../../app/pages/Profile/core/crud-helper/_requests";

type Props = {
  callback: any;
  copy: boolean;
};

const HeaderUserMenu: FC<Props> = (props) => {

  // const user: UserModel = useSelector<RootState>(
  //   ({ auth }) => auth.user,
  //   shallowEqual
  // ) as UserModel;

  const { data: userData } = useQuery(
    `profile-data`,
    () => {
      const data = getProfileData();
      return data;
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const dispatch = useDispatch();

  const logout = () => {
    dispatch(auth.actions.logout());
    Cookies.remove("userAuth");
  };

  function getInitials(firstName, lastName) {
    if (firstName && lastName) {
      return `${firstName[0]}${lastName[0]}`;
    }
    if (firstName) {
      return firstName[0];
    }
    if (lastName) {
      return lastName[0];
    }
    return "";
  }

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            {!userData?.data?.profile?.includes("default.jpg") &&
              userData?.data?.profile !== null ? (
              <img alt="Logo" src={userData?.data?.profile} />
            ) : (
              <div className="symbol-label fs-2 fw-bold text-dark uppercase">
                {getInitials(
                  userData?.data?.first_name,
                  userData?.data?.last_name
                )}
              </div>
            )}
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {userData?.data.first_name ? userData?.data.first_name : ""}{" "}
              {userData?.data.last_name ? userData?.data.last_name : ""}
            </div>
            {/* <div>
              <small>{user.vDesignation}</small>
            </div> */}
            <div className="d-flex align-items-center">
              <CopyToClipboard
                text={userData?.data.email}
                onCopy={() => props.callback(true)}
              >
                <span className="cursor-pointer email-icon">
                  <KTSVG
                    path="/media/icons/duotone/General/Envelope.svg"
                    className="svg-icon-gray-400 svg-icon-2"
                  />
                </span>
              </CopyToClipboard>
              {props.copy ? (
                <span style={{ color: "green" }}> &nbsp; Email Copied.</span>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5">
        <Link to="/profile" className="menu-link px-5">
          My Profile
        </Link>
        {/* </Link> */}
      </div>
      {
        userData?.data?.role === "Admin" &&
        <div className="menu-item px-5">
          <Link to="/api-keys" className="menu-link px-5">
            Api Keys
          </Link>
        </div>
      }
      <div className="menu-item px-5">
        <a onClick={logout} className="menu-link px-5">
          Sign Out
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
