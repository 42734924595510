import React from "react";
import { Link } from "react-router-dom";

const ColumnNavigationCell = ({ title, path }: any) => {

  return (
    <Link
      to={`${path}`}
      className="member-board-link"
    >
      {title}
    </Link>
  );
};

export default ColumnNavigationCell;
