import React, { createContext, useContext, useState } from "react";
import {
  QueryState,
  UserQueryRequestContextProps,
  initialQueryRequest,
} from "./core/helper";

const UserQueryRequestContext =
  createContext<UserQueryRequestContextProps>(initialQueryRequest);

const UserRequestProvider = ({ children }) => {
  const [state, setState] = useState<QueryState>(initialQueryRequest.state);

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = { ...state, ...updates } as QueryState;
    setState(updatedState);
  };

  return (
    <UserQueryRequestContext.Provider value={{ state, updateState }}>
      {children}
    </UserQueryRequestContext.Provider>
  );
};
const useQueryRequest = () => useContext(UserQueryRequestContext);

export { UserRequestProvider, useQueryRequest };
