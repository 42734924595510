import { createContext } from "react";
import { QueryResponseContextProps } from "./_models";

export const columns = [
  { colHeader: "School State" },
  { colHeader: "School District" },
  { colHeader: "School Board" },
  { colHeader: "No.Of Schools" },
  { colHeader: "Address" },
  { colHeader: "WebSites" },
];

export function createOption(id, name) {
  return {
    id,
    name,
  };
}

export type DashboardQueryRequestContextProps = {
  state: QueryState;
  updateState: (updates: Partial<QueryState>) => void;
};

export const initialQueryState: QueryState = {
  page: 1,
  limit: 50,
};

export const initialQueryRequest: DashboardQueryRequestContextProps = {
  state: initialQueryState,

  updateState: () => {},
};

export type QueryState = PaginationState &
  SortState &
  FilterState &
  SearchState;

export type SortState = {
  sort?: string;
  order?: "asc" | "desc";
};

export type FilterState = {
  filter?: unknown;
};

export type SearchState = {
  search?: string;
};

export type PaginationState = {
  page: number;
  // limit: 10 | 30 | 50 | 100;
  limit: 30 | 50 | 100 | 200;

  // total?: number;
  total_records?: number;
  total_pages?: number;
};

export function createResponseContext<T>(
  initialState: QueryResponseContextProps<T>
) {
  return createContext(initialState);
}

export interface DashboardColumns {
  state?: string;
  district_name?: string;
  school_board?: string;
  no_of_schools?: string;
  address?: string;
  website?: string;
  board_member_website_URLs?: string;
}
