import React from "react";
import DashboardFilter from "./DashboardFilter";
import { PageTitle } from "../../../_metronic/layout/core";
import DashboardList from "./DashboardList";
import { DashboardRequestProvider } from "./DashboardRequestProvider";
import { DashboardResponseProvider } from "./DashboardResponseProvider";

const Dashboard = () => {
  return (
    <div>
      <PageTitle breadcrumbs={[]}>Dashboard </PageTitle>
      <DashboardRequestProvider>
        <DashboardResponseProvider>
          <DashboardFilter />
          <DashboardList />
        </DashboardResponseProvider>
      </DashboardRequestProvider>
    </div>
  );
};

export default Dashboard;
