import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const getUserLists = (data: any): Promise<any> => {
  return axios.post(`${API_URL}api/users/list`, data).then((response) => {
    return response.data;
  });
};

const userDelete = (data: any): Promise<any> => {
  return axios.post(`${API_URL}api/user/delete`, data).then((response) => {
    return response.data;
  });
};

const getStatesList = (): Promise<any> => {
  return axios.get(`${API_URL}api/states/list`).then((response) => {
    return response.data;
  });
};
const updateStatesList = (data: any): Promise<any> => {
  return axios.post(`${API_URL}api/permissions/edit`, data).then((response) => {
    return response.data;
  });
};

const getUserStateList = (data: any): Promise<any> => {
  return axios
    .post(`${API_URL}api/users/edit/states/list `, data)
    .then((response) => {
      return response.data;
    });
};

export {
  getUserLists,
  userDelete,
  getStatesList,
  updateStatesList,
  getUserStateList,
};
