import { createContext, useContext, useState } from "react";
import {
  QueryState,
  SchoolBoardQueryRequestContextProps,
  initialQueryRequest,
} from "./core/helper";


const SchoolBoardQueryRequestContext =
  createContext<SchoolBoardQueryRequestContextProps>(initialQueryRequest);

const SchoolBoardRequestProvider = ({ children }) => {
  const [state, setState] = useState<QueryState>(initialQueryRequest.state);
  const [memberState, setMemberStata] = useState<any>({});

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = { ...state, ...updates } as QueryState;
    setState(updatedState);
  };

  return (
    <SchoolBoardQueryRequestContext.Provider
      value={{
        state,
        updateState,
        boardMemberState: memberState,
        updateBoardMemberState: setMemberStata,
      }}
    >
      {children}
    </SchoolBoardQueryRequestContext.Provider>
  );
};
const useQueryRequest = () => useContext(SchoolBoardQueryRequestContext);

export { SchoolBoardRequestProvider, useQueryRequest };
