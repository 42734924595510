import React, { useContext, useEffect, useState, useMemo } from "react";
import {
  PaginationState,
  createResponseContext,
  initialQueryState,
} from "./core/helper";
import { initialQueryResponse } from "./core/_models";
import { useQuery } from "react-query";
import { stringifyRequestQuery } from "../Portfolio/core/crud-helper";
import { useQueryRequest } from "./SchoolBoardRequestProvider";
import { getSchoolBoardList } from "./core/_request";

const QueryResponseContext = createResponseContext<any>(initialQueryResponse);

const SchoolBoardResponseProvider = ({ children }) => {
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const [postData, setPostData] = useState<any>(state);

  useEffect(() => {
    if (query !== updatedQuery) {
      setPostData(state);
      setQuery(updatedQuery);
    } else {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `schoolBoard-list-${query}`,
    () => {
      if (postData?.filter && postData?.filter.state && postData?.filter.district) {
        const list = getSchoolBoardList(postData);
        return list;
      }
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  return (
    <QueryResponseContext.Provider
      value={{ response, isLoading: isFetching, refetch }}
    >
      {children}
    </QueryResponseContext.Provider>
  );
};
const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }

  return response?.data?.rows || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    total_pages: 0,
    ...initialQueryState,
  };
  const { response } = useQueryResponse();
  if (!response || !response.payload) {
    return defaultPaginationState;
  }
  return response?.payload;
};

const useQueryResponseLoading = () => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

const useQueryRefetch = () => {
  const { refetch } = useQueryResponse();
  return refetch;
};

export {
  SchoolBoardResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
  useQueryRefetch,
};
