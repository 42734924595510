/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";

import { shallowEqual, useSelector } from "react-redux";

import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { Logout, AuthPage } from "../modules/auth";
import { RootState } from "../../setup";
import { App } from "../App";
import Cookies from "js-cookie";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const { PUBLIC_URL } = process.env;
const AppRoutes: FC = () => {
  const isAuthorized = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );
  const accessToken = useSelector<RootState>(
    ({ auth }) => auth.accessToken,
    shallowEqual
  );

  const cookieVal = Cookies.get("userAuth");
  // const [cookie, setCookie] = useState<any>(false);

  useEffect(() => {
    if (accessToken !== cookieVal) {
      Cookies.remove("userAuth");
    }
  }, [cookieVal, accessToken]);

  return (
    <>
      <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
          <Route element={<App />}>
            <Route path="error/*" element={<ErrorsPage />} />
            <Route path="logout" element={<Logout />} />
            {isAuthorized && cookieVal ? (
              <>
                <Route path="/*" element={<PrivateRoutes />} />
                {/* <Route path="/login" element={<Navigate to="/" />} /> */}
                <Route index element={<Navigate to="/dashboard" />} />
              </>
            ) : (
              <>
                <Route path="/*" element={<AuthPage />} />
                <Route index element={<Navigate to="/login" />} />
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export { AppRoutes };
