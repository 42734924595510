import { FC } from "react";
import { ColumnInstance } from "react-table";
import { DashboardColumns } from "../../core/_models";

type Props = {
  column: ColumnInstance<DashboardColumns>;
};

const CustomHeaderColumn: FC<Props> = ({ column }) => {
  return (
    <>
      {column.Header && typeof column.Header === "string" ? (
        <th {...column.getHeaderProps()}>{column.render("Header")}</th>
      ) : (
        column.render("Header")
      )}
    </>
  );
};

export { CustomHeaderColumn };
