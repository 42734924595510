import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const getStateList = (data: any): Promise<any> => {
  return axios
    .post(`${API_URL}api/users/states/list`, data)
    .then((response) => {
      return response.data;
    });
};

const getDistrictList = (id: any): Promise<any> => {
  return axios
    .post(`${API_URL}api/users/district/list`, id)
    .then((response) => {
      return response.data;
    });
};

const getDashboardList = (data: any): Promise<any> => {
  return axios
    .post(`${API_URL}api/dashboard/schools/list`, data)
    .then((response) => {
      return response.data;
    });
};

const exportDashboardList = (data: any): Promise<any> => {
  return axios
    .post(`${API_URL}api/dashboard/export-school-data`, { filter: data })
    .then((response) => {
      return response.data;
    });
};

const updateDashboardData = (data: any): Promise<any> => {
  return axios.post(`${API_URL}api/district/edit`, data).then((response) => {
    return response.data;
  });
};

export {
  getStateList,
  getDistrictList,
  getDashboardList,
  exportDashboardList,
  updateDashboardData,
};
