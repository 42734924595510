import React, { useState } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import Swal from "sweetalert2";
// import { userDelete } from "../../core/_request";
// import { useQueryRefetch } from "../../UserResponseProvider";
import { deleteBoardMember } from "../../core/_request";
import { useQueryRefetch } from "../../SchoolBoardResponseProvider";
import SchoolBoardModel from "../../SchoolBoardModel";

const ColumnActionCell = ({ row }: any) => {
  const refetch = useQueryRefetch();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOnDelete = (id: any) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then(function (result) {
      if (result.value) {
        deleteBoardMember({ id: id }).then((res) => {
          if (res.status === 200) {
            refetch?.();
            Swal.fire("Deleted!", res.message, "success");
          }
        });
      }
    });
  };

  return (
    <div>
      <div className="d-flex gap-3">
        <div
          className="symbol-label bg-light p-1 border-radius-4 users-action"
          onClick={handleShow}
        >
          <KTSVG
            path="/media/AFP-Icons/edit.svg"
            className="svg-icon-1 svg-icon-primary mb-2"
          />
        </div>
        <div
          className="symbol-label bg-light p-1 users-action"
          onClick={() => handleOnDelete(row?.original?.id)}
        >
          <KTSVG
            path="/media/AFP-Icons/delete.svg"
            className="svg-icon-1 svg-icon-primary mb-2"
          />
        </div>
      </div>
      <SchoolBoardModel open={show} onClose={handleClose} editData={row} />
    </div>
  );
};

export default ColumnActionCell;
