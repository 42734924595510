import { Dispatch, SetStateAction, createContext } from "react";
import { QueryResponseContextProps } from "./_models";

export type SchoolBoardQueryRequestContextProps = {
  state: QueryState;
  updateState: (updates: Partial<QueryState>) => void;

  boardMemberState: any;
  updateBoardMemberState: (updates: Partial<any>) => void;
};

export type ListViewContextProps = {
  isAllSelected:any;
  onSelectAll:any;
  onSelect:any;
  selected:any;
  setSelected:any;
};

export type QueryState = PaginationState & SortState & FilterState;

//   SearchState;
export type FilterState = {
  filter?: unknown;
};

export type PaginationState = {
  page: number;
  // limit: 10 | 30 | 50 | 100;
  limit: 10 | 30 | 50 | 100;

  // total?: number;
  total_records?: number;
  total_pages?: number;
};

export type SortState = {
  sort?: string;
  order?: "asc" | "desc";
};

export const initialQueryState: QueryState = {
  page: 1,
  limit: 50,
};

export const initialQueryRequest: SchoolBoardQueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},

  boardMemberState: {},
  updateBoardMemberState: () => {},
};

export const initialListRequest:ListViewContextProps = {
  isAllSelected:"",
  onSelectAll:()=>{},
  onSelect:()=>{},
  selected:[],
  setSelected: () => {},
}

export function createResponseContext<T>(
  initialState: QueryResponseContextProps<T>
) {
  return createContext(initialState);
}

export function createOption(id: any, name: string) {
  return {
    id,
    name,
  };
}


export function calculateIsAllDataSelected<T>(data: Array<T> | undefined, selected: Array<any>): boolean {
  if (!data) {
    return false
  }

  return data.length > 0 && data.length === selected.length
}

export function groupingOnSelect(
  id: any,
  selected: Array<any>,
  setSelected: Dispatch<SetStateAction<Array<any>>>
) {
  if (!id) {
    return
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id))
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(id)
    setSelected(updatedSelected)
  }
}

export function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<any>>>,
  data?: Array<T & {id?: any}>
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }

  setSelected(data.filter((item) => item.id).map((item) => item.id))
}