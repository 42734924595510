import clsx from "clsx";
import React, { FC, useState } from "react";
// import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu } from "../../../partials";
import { getProfileData } from "../../../../app/pages/Profile/core/crud-helper/_requests";
import { useQuery } from "react-query";

const toolbarButtonMarginClass = "ms-1 ms-lg-3",
  // toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px";
// ,toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  // const user: UserModel = useSelector<RootState>(
  //   ({ auth }) => auth.user,
  //   shallowEqual
  // ) as UserModel;

  const { data: userData } = useQuery(
    `profile-data`,
    () => {
      const data = getProfileData();
      return data;
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const [copied, setCopied] = useState(false);

  const copyEmailAddress = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
        id="kt_header_user_menu_toggle"
      >
        {/* begin::Toggle */}
        <div
          className={clsx(
            "cursor-pointer symbol",
            toolbarUserAvatarHeightClass
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          {!userData?.data?.profile?.includes("/default.jpg") &&
          userData?.data?.profile !== null ? (
            <img
              src={userData?.data?.profile ? userData?.data?.profile : ""}
              alt="Profile"
            />
          ) : (
            <div className="symbol symbol-50px">
              <div className="symbol-label fs-2 fw-bold text-dark uppercase">
                {`${userData?.data?.first_name?.charAt(
                  0
                )}${userData?.data?.last_name?.charAt(0)}`}
              </div>
            </div>
          )}
        </div>

        <HeaderUserMenu callback={copyEmailAddress} copy={copied} />
        {/* end::Toggle */}
      </div>
      {/* end::User */}
      {/* </div> */}
    </div>
  );
};

export { Topbar };
