import React, { useEffect, useState } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { KTCard, KTCardBody } from "../../../_metronic/helpers";
import Table from "../../components/Table";
import { useQuery } from "react-query";
import { createUser, editUser, getStatesList } from "./core/_request";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getUserStateList } from "../userlisting/core/_request";
import { Loader } from "../../components";

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  confirm_password: "",
  role: ""
};

const CreateUser = () => {
  const { id } = useParams();
  const userSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Wrong email address")
      .required("Email address is required"),
    // password: Yup.string()
    //   .required("Password address is required")
    //   .min(8, "Password is too short - should be 8 chars minimum.")
    //   .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
    // confirm_password: Yup.string().oneOf(
    //   [Yup.ref("password"), null],
    //   "Passwords must match"
    // ),

    password: Yup.string()
      .concat(
        !id ? Yup.string().required("Password is required") : Yup.string()
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
        "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
    confirm_password: Yup.string()
      .concat(
        !id
          ? Yup.string()
            .required("Confirm Password is required")
            .oneOf(
              [Yup.ref("password"), null],
              "Password and Confirm Password must match"
            )
          : Yup.string().when("password", {
            is: (password: any) =>
              password && password.length > 0 ? true : false,
            then: Yup.string()
              .oneOf(
                [Yup.ref("password"), null],
                "Password and Confirm Password must match"
              )
              .required("Confirm Password is required"),
          })
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
        "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
    role: Yup.string().required("User Type is required"),
  });

  const [rowData, setRowData] = useState<any>([]);
  const [permissions, setPermissions] = useState<any>([]);
  const [stateData, setStateData] = useState<any>([]);
  const [selectAll, setSelectAll] = useState<any>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");
  const location = useLocation();

  const columns = [
    { colHeader: "State" },
    { colHeader: "Edit" },
    { colHeader: "View" },
  ];

  function createData(state, edit, view) {
    return { state, edit, view };
  }

  useEffect(
    () => {
      if (id) {
        getUserStateList({ user_id: id })
          .then((response) => {
            if (response.status == 200) {
              setStateData(response?.data);
              const output = response?.data?.map((obj) => {
                return { state_id: obj.id, edit: obj.edit, view: obj.view };
              });
              setPermissions(output);
              // setLoading(true);
            }
          })
          .catch((error) => {
            console.log(error);
            // setLoading(true);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const { data: response, isLoading } = useQuery(
    `state-list`,
    () => {
      const list = getStatesList();
      return list;
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );
  const formik = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: async (values, { setErrors, resetForm }) => {
      setIsSubmitting(true)
      const data = { ...values, permissions: permissions };
      const editData: any = {
        user_id: id,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        ...(values?.password && {
          password: data?.password,
        }),
        permissions: permissions,
        role: values?.role
      };
      if (id) {
        var response = await editUser(editData);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-redeclare
        var response = await createUser(data);
      }

      if (response?.status === 200) {
        Swal.fire({
          toast: true,
          title: response?.message,
          icon: "success",
          width: "30rem",
          position: "top-right",
          timer: 2500,
          timerProgressBar: true,
          showConfirmButton: false,
          showCancelButton: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        formik.resetForm();
        setIsSubmitting(false)
        setTimeout(() => {
          navigate("/users");
        }, 500);
      }
      else if (response?.status === 101) {
        setErrors(response?.message)
        Swal.fire({
          toast: true,
          title: response?.message,
          icon: "error",
          width: "30rem",
          position: "top-right",
          timer: 2500,
          timerProgressBar: true,
          showConfirmButton: false,
          showCancelButton: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        setIsSubmitting(false)
      }
      else {
        Swal.fire({
          toast: true,
          title: "Something went wrong",
          icon: "error",
          width: "30rem",
          position: "top-right",
          timer: 2500,
          timerProgressBar: true,
          showConfirmButton: false,
          showCancelButton: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        setIsSubmitting(false)
      }

    },
  });
  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik?.errors)?.length > 0) {
      document.getElementsByName(Object?.keys(formik.errors)?.[0])?.[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [formik]);
  const handleOnPermision = (e, id: any) => {
      var val: any = {
        state_id: id,
        [e.target.value]: e.target.checked ? "1" : "0",
      };
      if (val.edit === undefined) {
        val.edit = "0";
      }
      if (val.view === undefined) {
        val.view = "0";
      }

      setPermissions((prev)=>{
        if(!prev.find((obj)=>obj.state_id===id)){
          return [...prev,val]
        }else{
          let tempPermission = prev?.map((obj)=>{
            if(obj.state_id==id){
              return {...obj,[e.target.value]: e.target.checked ? "1" : "0"}
            }
            else{
              return obj
            }
          })
          return [...tempPermission]
        }
    })
  };
  const handleSelectAll = (e:any)=>{
    setSelectAll(e.target.checked)
    let tempPermissions:any = []
    if(response?.data?.length >0){
      response?.data?.map((object)=>{
        tempPermissions?.push({state_id:object?.id,edit:e.target.checked ? "1" : "0",view:e.target.checked ? "1" : "0"})
      })
    }

    setPermissions([...tempPermissions])
  }
  useEffect(() => {
    if (response?.data?.length > 0) {
      const rows = response?.data?.map((obj, index) =>
        {
          return createData(
            obj?.value,
            <input
              type="checkbox"
              value={"edit"}
              className="permision-checkbox"
              checked={permissions?.find((obj1)=>(obj?.id==obj1?.state_id && obj1?.edit == "1")) ? true : false}
              onChange={(e) => handleOnPermision(e, obj.id)}
            />,
            <input
              type="checkbox"
              value={"view"}
              className="permision-checkbox"
              checked={permissions?.find((obj1)=>(obj?.id==obj1?.state_id && obj1?.view == "1")) ? true : false}
              onChange={(e) => handleOnPermision(e, obj.id)}
            />
          )
        }
      );

      setRowData(rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response,permissions]);

  useEffect(() => {
    if (location?.state) {
      formik.setFieldValue("first_name", location?.state?.first_name);
      formik.setFieldValue("last_name", location?.state?.last_name);
      formik.setFieldValue("email", location?.state?.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state]);

  return (
    <div>
      <PageTitle breadcrumbs={[]}>
        {splitLocation[2] === "edituser" ? "Edit User" : "Add User"}
      </PageTitle>
      <div className="card">
        <div className="card-body p-4">
          <div className="col-md-12">
            <h3 className="create-user-title">User Details</h3>
            <form className="form" noValidate onSubmit={formik.handleSubmit}>
              <div className="fv-row row align-items-center mb-2">
                <div className="col-12 col-md-6  px-2 pb-6 ">
                  <label className="fw-bold fs-6 mb-2 required">
                    First Name
                  </label>
                  <input
                    className="form-control"
                    {...formik.getFieldProps("first_name")}
                    type="text"
                    name="first_name"
                    placeholder="Enter First Name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.first_name && formik.errors.first_name && (
                    <div className="fv-plugins-message-container position-absolute">
                      <span role="alert">{formik.errors.first_name}</span>
                    </div>
                  )}
                </div>
                <div className="col-12 col-md-6   px-2 pb-6 ">
                  <label className="fw-bold fs-6 mb-2 required">
                    Last Name
                  </label>
                  <input
                    className="form-control"
                    {...formik.getFieldProps("last_name")}
                    type="text"
                    name="last_name"
                    placeholder="Enter Last Name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.last_name && formik.errors.last_name && (
                    <div className="fv-plugins-message-container position-absolute">
                      <span role="alert">{formik.errors.last_name}</span>
                    </div>
                  )}
                </div>
                <div className="col-12 col-md-6  px-2 pb-4 ">
                  <label className="fw-bold fs-6 mb-2 required">Email</label>
                  <input
                    className="form-control"
                    {...formik.getFieldProps("email")}
                    type="text"
                    name="email"
                    placeholder="Enter Email Address"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="fv-plugins-message-container position-absolute">
                      <span role="alert">{formik.errors.email}</span>
                    </div>
                  )}
                </div>
                <div className="col-12 col-md-6 col-lg-3  px-2 pb-4 ">
                  <label
                    className={`fw-bold fs-6 mb-2  ${!id
                        ? "required"
                        : formik.values.confirm_password !== ""
                          ? "required"
                          : ""
                      }`}
                  >
                    Password
                  </label>
                  <input
                    className="form-control"
                    {...formik.getFieldProps("password")}
                    type="password"
                    name="password"
                    placeholder="Enter Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="fv-plugins-message-container position-absolute passwordWidth">
                      <span role="alert">{formik.errors.password}</span>
                    </div>
                  )}
                </div>
                <div className="col-12 col-md-6 col-lg-3  px-2 pb-6 ">
                  <label
                    className={`fw-bold fs-6 mb-2 ${!id
                        ? "required"
                        : formik.values.password !== ""
                          ? "required"
                          : ""
                      }`}
                  >
                    Confirm Password
                  </label>
                  <input
                    className="form-control"
                    {...formik.getFieldProps("confirm_password")}
                    type="password"
                    name="confirm_password"
                    placeholder="Enter Confirm Password"
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.confirm_password &&
                    formik.errors.confirm_password && (
                      <div className="fv-plugins-message-container position-absolute">
                        <span role="alert">
                          {formik.errors.confirm_password}
                        </span>
                      </div>
                    )}
                </div>
                <div className="col-12 col-md-6  px-2 pb-4">
                  <label className="fw-bold fs-6 mb-2 required">
                    User Type
                  </label>
                  <select name={'role'} value={formik.values.role} className="form-control" onChange={formik.handleChange} onBlur={formik.handleBlur}>
                    <option value={''} disabled>Select User</option>
                    <option value={'Admin'}>Admin</option>
                    <option value={'User'}>User</option>
                  </select>
                  {formik.touched.role && formik.errors.role && (
                    <div className="fv-plugins-message-container position-absolute">
                      <span role="alert">{formik.errors.role}</span>
                    </div>
                  )}
                </div>
              </div>
              {formik.values.role !== "Admin" ?
                <KTCard className="my-7">
                  <div className="d-flex justify-content-between pr-5 mr-4 align-items-center">
                    <h3 className="create-user-title">Permission</h3>
                    <h3 className="">
                      <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                        <input
                          className="permision-checkbox"
                          type='checkbox'
                          checked={selectAll}
                          onChange={handleSelectAll}
                          />
                          <span className="ml-5">Select All</span>
                      </div>
                    </h3>
                  </div>
                  <KTCardBody className="p-0 ">
                    <Table
                      cols={columns}
                      rows={rowData}
                      className="permission-table"
                    />
                  </KTCardBody>
                </KTCard> : <></>}
              <div>
                {typeof formik?.errors === 'string' ?

                  <div className="fv-plugins-message-container pb-8">
                    <span role="alert">{formik?.errors}</span>
                  </div>
                  :
                  <></>}
              </div>
              <div>
                <button
                  type="submit"
                  className="btn btn-primary btn-sm me-3"
                  disabled={isSubmitting}
                // data-kt-users-modal-action="submit"
                >
                  <span className="indicator-label">
                    {splitLocation[2] === "edituser" ? "Edit User" : "Add User"}
                    {isSubmitting &&
                      <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>}
                  </span>
                </button>
                <button
                  type="button"
                  className="btn btn-secondary btn-sm me-3"
                  data-kt-menu-dismiss="true"
                  onClick={() => navigate("/users")}
                >
                  <span className="indicator-label">Back</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {(isLoading || isSubmitting) && <Loader />}
    </div>
  );
};

export default CreateUser;
