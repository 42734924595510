import { FC, useMemo } from 'react'
import { useListRequest } from '../../ListViewProvider';


const UserSelectionCell: FC<any> = ({ id }) => {
  const { selected , onSelect} = useListRequest();
  const isSelected = useMemo(() => selected.includes(id), [id, selected])
  return (
    <div className='form-check form-check-sm form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='checkbox'
        data-kt-check-target='#kt_table_users .form-check-input'
        checked={isSelected}
        onChange={() => onSelect(id)}
      />
    </div>
  )
}

export { UserSelectionCell }
