import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const getSchoolBoardList = (data: any): Promise<any> => {
  return axios
    .post(`${API_URL}api/board-members/list`, data)
    .then((response) => {
      return response.data;
    });
};

const deleteBoardMember = (data: any): Promise<any> => {
  return axios
    .post(`${API_URL}api/board-member/delete`, data)
    .then((response) => {
      return response.data;
    });
};

const deleteMultipleBoardMember = (data: any): Promise<any> => {
  return axios
    .post(`${API_URL}api/board-member/delete-multiple`, data)
    .then((response) => {
      return response.data;
    });
};

const createBoardMember = (data: any): Promise<any> => {
  return axios
    .post(`${API_URL}api/board-member/create`, data)
    .then((response) => {
      return response.data;
    });
};

const updateBoardMember = (data: any): Promise<any> => {
  return axios
    .post(`${API_URL}api/board-member/update`, data)
    .then((response) => {
      return response.data;
    });
};

const getStateList = (data: any): Promise<any> => {
  return axios
    .post(`${API_URL}api/users/states/list`, data)
    .then((response) => {
      return response.data;
    });
};

const getDistrictList = (id: any): Promise<any> => {
  return axios
    .post(`${API_URL}api/users/district/list`, id)
    .then((response) => {
      return response.data;
    });
};

const exportSchoolBoardMember = (data: any): Promise<any> => {
  return axios
    .post(`${API_URL}api/board-member/export-member-data`, { filter: data })
    .then((response) => {
      return response.data;
    });
};

export {
  getSchoolBoardList,
  deleteBoardMember,
  createBoardMember,
  updateBoardMember,
  getStateList,
  getDistrictList,
  exportSchoolBoardMember,
  deleteMultipleBoardMember
};
