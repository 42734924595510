import { FC, useState } from 'react'
import { deleteApiKey } from "../../core/_request"
import { KTSVG } from '../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { Toast } from '../../../../components'
import AddEditApiKey from '../../add/AddEditApiKey'
type Props = {
    data?: any
    className?: string
}
const UserActionsCell: FC<Props> = ({ data, className }) => {

    const { refetch } = useQueryResponse()

    const [show, setShow] = useState<boolean>(false)
    const handleDeletApiKey = () => {
        const delet_row = {
            id: data?.id
        }
        Swal.fire({
            title: 'Delete',
            text: 'Are you sure you want to delete',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#173748',
            cancelButtonColor: '#EFF2F5',
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                cancelButton: 'btn btn-light cancelBtn',
            },
        }).then((response) => {
            if (response.isConfirmed === true) {
                deleteApiKey(delet_row).then((response) => {
                    if (response.status === 200) {
                        Toast.fire({
                            icon: 'success',
                            title: response.message,
                        })
                        refetch()
                    }
                })
            }
        })
    }

    const openEditModal = () => {
        setShow(true)
    }
    return (
        <>
            {
                show && <AddEditApiKey show={show} setShow={setShow} data={data} isEdit={true} />
            }
            <div className={className}>
                <span
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 mb-2'
                    data-kt-users-table-filter='delete_row'
                    onClick={openEditModal}
                    title='Edit ApiKey'
                >
                    <KTSVG
                        path="/media/AFP-Icons/edit.svg"
                        className="svg-icon-1 svg-icon-primary mb-2"
                    />
                </span>
                <span
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm menu-link px-3 mb-2'
                    onClick={handleDeletApiKey}
                >
                    <KTSVG
                        path="/media/AFP-Icons/delete.svg"
                        className="svg-icon-1 svg-icon-primary mb-2"
                    />
                </span>
            </div>
        </>

    )
}

export { UserActionsCell }
