import { FC, PropsWithChildren, useMemo } from 'react'
import { HeaderProps } from 'react-table'
import { SchoolBoardMemberListingColumns } from '../../core/_models';
import { useQueryRequest } from '../../SchoolBoardRequestProvider';
import clsx from 'clsx';
import { initialQueryState } from '../../core/helper';
import { useListRequest } from '../../ListViewProvider';


type Props = {
  className?: string;
  title?: string;
  tableProps: PropsWithChildren<HeaderProps<SchoolBoardMemberListingColumns>>;
  isSortable?: boolean;
};

const UserSelectionHeader: FC<Props> = ({ 
  className,
  title,
  tableProps,
  isSortable = true,
 }) => {
  const id = tableProps.column.id;

  const { state, updateState } = useQueryRequest();

  const isSelectedForSorting = useMemo(() => {
    return state.sort && state.sort === id;
  }, [state, id]);

  const order: "asc" | "desc" | undefined = useMemo(() => state.order, [state]);

  const sortColumn = () => {
    // avoid sorting for these columns
    if (id === "actions" || id === "selection") {
      return;
    }

    // avoid sorting for these columns
    if (!isSortable) {
      return;
    }

    if (!isSelectedForSorting) {
      // enable sort asc
      updateState({ sort: id, order: "asc", ...initialQueryState });
      return;
    }

    if (isSelectedForSorting && order !== undefined) {
      if (order === "asc") {
        // enable sort desc
        updateState({ sort: id, order: "desc", ...initialQueryState });
        return;
      }

      // disable sort
      updateState({ sort: undefined, order: undefined, ...initialQueryState });
    }
  };
  const { isAllSelected , onSelectAll} = useListRequest();

  return (
    <th {...tableProps.column.getHeaderProps()} className={clsx(
      className,
      "fw-bolder",
      "fs-6"
    )}>
      <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
        <input
          className='form-check-input'
          type='checkbox'
          data-kt-check-target='#kt_table_users .form-check-input'
          checked={isAllSelected}
          onChange={onSelectAll}
        />
      </div>
    </th>
  )
}

export { UserSelectionHeader }
