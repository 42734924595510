/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
// import * as auth from '../redux/AuthRedux'
import { changePassword, veryToken } from "../redux/AuthCRUD";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";

const initialValues = {
  password: "",
  changepassword: "",
  token: "",
};

const registrationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Minimum 8 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  changepassword: Yup.string()
    .required("Confirm password is required")
    .when("password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password and confirm password does not match."
      ),
    }),
});

export function ResetPassword() {
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [message, setMessage] = useState<string>("");

  let history = useNavigate();
  let location = useLocation();



  const { token } = useParams();

  const useQuery = () => new URLSearchParams(location.search);

  const query = useQuery();

  const email = query.get("email");

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      changePassword(email, values.password, values.changepassword)
        .then(({ data }) => {
          setLoading(false);
          if (data.status === 200) {
            setHasErrors(false);
            // setMessage(data.message);
            setSubmitting(false);
            history('/login', { replace: true, state: {success: data.message} });
            /* setTimeout(() => {
              history("/login");
            }, 2000); */
          } else {
            // setHasErrors(true);
            // setMessage(data.message);
            setSubmitting(false);
            history('/login', { replace: true, state: {error: data.message} });
            // setTimeout(() => {
            //   history('/login');
            // }, 2000)
          }
        })
        .catch((error) => {
          setLoading(false);
          setSubmitting(false);
          setHasErrors(true);
          setMessage(error.response.data.message);
        });
    },
  });

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
    //setEyeSymbol(passwordShown ? "fa-eye" : "fa-eye-slash");
  };

  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
    //setEyeSymbol(passwordShown ? "fa-eye" : "fa-eye-slash");
  };

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  const verifyData: any = {
    email: email,
    token: token,
  };

  useEffect(() => {
    const veryTokenData = async () => {
      const response = await veryToken(verifyData);
      if (
        response.data.status === 101 &&
        response.data.message === "This link is not valid!"
      ) {
        history("/error");
      }
    };
    veryTokenData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Title */}
      {hasErrors === true && message !== "" && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{message}</div>
        </div>
      )}

      {hasErrors === false && message !== "" && (
        <div className="mb-10 bg-light-info p-8 rounded">
          <div className="text-info">{message}</div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className="mb-10 text-center">
        {/* begin::Title */}
        <h1 className="text-dark mb-3">Reset Password</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Password */}
      <div className="mb-10 fv-row" data-kt-password-meter="true">
        <div className="mb-1">
          <label className="form-label fw-bolder text-dark fs-6">
            New Password*
          </label>
          <div className="position-relative mb-3">
            <input
              type={passwordShown ? "text" : "password"}
              placeholder="Password"
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control form-control-lg form-control-solid"
                /* {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                } */
              )}
            />
            <i
              className={
                passwordShown
                  ? "fa fa-solid fa-eye cursor-pointer"
                  : "fa fa-solid fa-eye-slash cursor-pointer"
              }
              onClick={togglePasswordVisiblity}
            ></i>

            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className="d-flex align-items-center mb-3"
            data-kt-password-meter-control="highlight"
          >
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className="text-muted">
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className="fv-row mb-5">
        <label className="form-label fw-bolder text-dark fs-6">
          Confirm Password*
        </label>
        <div className="position-relative mb-3">
          <input
            type={confirmPasswordShown ? "text" : "password"}
            placeholder="Password confirmation"
            autoComplete="off"
            {...formik.getFieldProps("changepassword")}
            className={clsx(
              "form-control form-control-lg form-control-solid"
              /* {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            } */
            )}
          />
          <i
            className={
              confirmPasswordShown
                ? "fa fa-solid fa-eye cursor-pointer"
                : "fa fa-solid fa-eye-slash cursor-pointer"
            }
            onClick={toggleConfirmPasswordVisiblity}
          ></i>

          {formik.touched.changepassword && formik.errors.changepassword && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.changepassword}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}
      {/* begin::Form group */}
      <div className="d-flex flex-wrap justify-content-center pb-lg-0">
        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-lg btn-primary fw-bolder me-4"
        >
          <span className="indicator-label">Submit</span>
          {loading && (
            <span className="indicator-progress">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/login">
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            className="btn btn-lg btn-light-primary fw-bolder"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{" "}
      </div>
      {/* end::Form group */}
    </form>
  );
}
