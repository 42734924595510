import { FC, Suspense } from "react";
import {
  Route,
  Routes,
  Navigate /* useLocation, matchPath */,
} from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import MyProfile from "../pages/Profile/MyProfile";
import Dashboard from "../pages/Dashboard/Dashboard";
import CreateUser from "../pages/Createuser/CreateUser";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../setup";
import UserListMain from "../pages/userlisting/UserListMain";
import SchoolBoardMemberMain from "../pages/SchoolBoardMember/SchoolBoardMemberMain";
import ApikeyMangementWrapper from '../pages/ApiKeyManagementPage/ApikeyMangementWrapper'
// import ApiKeyCreate from "../pages/ApiKeyManagementPage/add/ApiKeyCreate";
// import EditApikey from "../pages/ApiKeyManagementPage/add/EditApikey";
const PrivateRoutes = () => {
  const isAuthorized: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );

  return (
    <Routes>

      <Route path="/*" element={<Navigate to="/dashboard" />} />
      <Route
        path="dashboard"
        element={
          <MasterLayout>
            <SuspensedView>
              <Dashboard />
            </SuspensedView>
          </MasterLayout>
        }
      />


      <Route
        path="/profile"
        element={
          <MasterLayout>
            <SuspensedView>
              <MyProfile />
            </SuspensedView>
          </MasterLayout>
        }
      />
      {
        isAuthorized?.role === 'Admin' &&
        <Route
          path="/api-keys"
          element={
            <MasterLayout>
              <SuspensedView>
                <ApikeyMangementWrapper />
              </SuspensedView>
            </MasterLayout>
          }
        />
      }

      <Route
        path="/schoolboardmember"
        element={
          <MasterLayout>
            <SuspensedView>
              <SchoolBoardMemberMain />
            </SuspensedView>
          </MasterLayout>
        }
      />

      {isAuthorized?.role === "Admin" && (
        <>
          <Route
            path="/users/adduser"
            element={
              <MasterLayout>
                <SuspensedView>
                  <CreateUser />
                </SuspensedView>
              </MasterLayout>
            }
          />
          <Route
            path="/users/edituser/:id"
            element={
              <MasterLayout>
                <SuspensedView>
                  <CreateUser />
                </SuspensedView>
              </MasterLayout>
            }
          />

          <Route
            path="/users"
            element={
              <MasterLayout>
                <SuspensedView>
                  <UserListMain />
                </SuspensedView>
              </MasterLayout>
            }
          />
        </>
      )}
      <Route path="*" element={<Navigate to="/error/404" />} />
    </Routes>
  );
};

const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
