import React from "react";
import { UserRequestProvider } from "./UserRequestProvider";
import UserListing from "./UserListing";
import { UserResponseProvider } from "./UserResponseProvider";
import { PageTitle, RightSideContent } from "../../../_metronic/layout/core";
import { useNavigate } from "react-router-dom";

const UserListMain = () => {
  const navigate = useNavigate();

  return (
    <div className="">
      <PageTitle breadcrumbs={[]}>Users</PageTitle>
      {/* <div className="add-new-user-btn"> */}
      <RightSideContent>
        <button
          type="button"
          className="btn btn-primary btn-sm me-3"
          data-kt-menu-dismiss="true"
          // data-kt-user-table-filter="filter"
          onClick={() => navigate("adduser")}
        >
          <span className="indicator-label">Add New User</span>
        </button>
      </RightSideContent>
      {/* </div> */}
      <UserRequestProvider>
        <UserResponseProvider>
          <UserListing />
        </UserResponseProvider>
      </UserRequestProvider>
    </div>
  );
};

export default UserListMain;
