import { Column } from "react-table";
import { DashboardColumns } from "../../core/_models";
import { CustomHeader } from "./CustomHeader";
import ColumnActionCell from "./ColumnActionCell";
import CopyToClipBoardActionCell from "./CopyToClipBoardActionCell";
import ColumnNavigationCell from "./ColumnNavigationCell";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

const usersColumns: Array<Column<DashboardColumns>> = [
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="School State"
        className="min-w-150px"
      />
    ),
    accessor: "state",
    Cell: ({ row }) => {
      // @ts-ignore: Unreachable code error
      return (
        <div className="row">
          <div className="col-md-12">
            <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
              {row?.original?.state}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="School District"
        className="min-w-300px"
      />
    ),
    accessor: "district_name",
    Cell: ({ row }:any) => {
      // @ts-ignore: Unreachable code error
      let options = {
          state: { id: row?.original?.state_id, name: row?.original?.state },
          district: {
            id: row?.original?.id,
            name: row?.original?.district_name,
        },
      }
      return (
        <div className="row">
          <div className="col-md-10">
            <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
              <ColumnNavigationCell  title={row?.original?.district_name} path={`/schoolboardmember?state_id=${row?.original?.state_id}&district_id=${row?.original?.id}`} />
            </span>
          </div>
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Board Member"
        className="min-w-150px"
        isSortable={false}
      />
    ),
    id: "school_board_member",
    Cell: ({ row }:any) => {
     
      return (
        <div className="row">
          <div className="col-md-10">
            <div className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
              {row?.original?.school_board_member ? (
                <ColumnNavigationCell  title={row?.original?.school_board_member} path={`/schoolboardmember?state_id=${row?.original?.state_id}&district_id=${row?.original?.id}`} />
              ) : (
                "-"
              )}
            </div>
          </div>
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="No. Of Schools"
        className="min-w-200px"
      />
    ),
    accessor: "no_of_schools",
    Cell: ({ row }) => {
      return (
        <div className="row">
          <div className="col-md-10">
            <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
              {row?.original?.no_of_schools
                ? row?.original?.no_of_schools
                : "-"}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Address"
        className="min-w-300px"
      />
    ),
    id: "address",
    Cell: ({ row }) => {
      // @ts-ignore: Unreachable code error
      return (
        <div className="row">
          <div className="col-md-10">
            <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
              {row?.original?.address}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="WebSite"
        className="min-w-250px"
      />
    ),
    id: "website",
    Cell: ({ row }) => {
      // @ts-ignore: Unreachable code error
      return (
        <div className="row">
          <div className="col-md-10 dashboard-website-column">
            <span className="text-dark fw-bolder text-hover-primary  fs-6">
              {row?.original?.website || "-"}
            </span>
            {row?.original?.website && (
              <CopyToClipBoardActionCell text={row?.original?.website} />
            )}
          </div>
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Board member Website URLs"
        className="min-w-300px"
      />
    ),
    id: "board_member_website_URLs",
    Cell: ({ row }) => {
      // @ts-ignore: Unreachable code error

      var url_status = {};
      if (
        row?.original?.url_status !== undefined &&
        row?.original?.url_status !== null
      ) {
        try {
          url_status = JSON.parse(row?.original?.url_status);
        } catch (error) {
          url_status = {};
        }
      }

      return (
        <div className="row" role="button">
          <div className="col-md-10 dashboard-website-column d-flex align-items-center">
            <span className="text-dark fw-bolder text-hover-primary  fs-6 d-flex align-items-center">
              {row?.original?.board_member_website_URLs?.split(",").length ||
                "-"}
            </span>
            {row?.original?.board_member_website_URLs?.split(",").length >
              0 && (
              <span className=" d-flex align-items-center h-100">
                <i
                  className="fas fa-exclamation-circle timelog-indicator-darkgreen"
                  data-tip
                  data-for={`board_member_website_URLs_${row.id}`}
                  style={{ color: "black" }}
                ></i>
                <ReactTooltip
                  id={`board_member_website_URLs_${row.id}`}
                  delayHide={500}
                  className="extraClass"
                >
                  {row?.original?.board_member_website_URLs.trim() ==
                  "no url added"
                    ? row?.original?.board_member_website_URLs
                    : row?.original?.board_member_website_URLs
                        .split(",")
                        .map((url, urlIndex) => {
                          return (
                            <div key={`url-${urlIndex}`}>{url?.trim()} {url_status[url?.trim()] !== undefined ? ' - ' + url_status[url?.trim()] : ''}</div>
                          );
                        })}
                </ReactTooltip>
              </span>
            )}
          </div>
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Action"
        className="min-w-250px"
        isSortable={false}
      />
    ),
    id: "eAction",
    Cell: ({ row }) => {
      // @ts-ignore: Unreachable code error
      return (
        <div className="row">
          <div className="">
            {/* {row?.original?.edit ? <ColumnActionCell row={row} /> : "-"} */}
            {<ColumnActionCell row={row} />}
          </div>
        </div>
      );
    },
  },
];

export { usersColumns };
