import { useRef } from 'react';
import { UserCustomHeader } from './UserCustomHeader'
import { UserActionsCell } from './UserActionsCell'
import moment from 'moment';
import ReactTooltip from "react-tooltip";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const usersColumns: any = [
    {
        Header: (props: any) => (
            <UserCustomHeader tableProps={props} title='Name' className='min-w-150px ' />
        ),
        accessor: 'apiName',
        Cell: ({ row }) => {
            // @ts-ignore: Unreachable code error
            return <div className='d-flex justify-content-start '>
                <span>
                    {row.original.token_name}
                </span>
            </div>
        },
    },
    {
        Header: (props: any) => (
            <UserCustomHeader tableProps={props} title='Api key' className='min-w-150px ' />
        ),
        accessor: 'apiKey',
        Cell: ({ row }) => {
            const toolRef = useRef<any>(null);
            const handleCopy = () => {
                ReactTooltip.show(toolRef.current);
                setTimeout(() => {
                    ReactTooltip.hide(toolRef.current);
                }, 3000);
            };
            function maskPassword(token: any) {
                return '*'.repeat(20);
            }
            // @ts-ignore: Unreachable code error

            return (

                < div className="row" >
                    <div className="col-md-16 d-flex ">
                        <span className='px-2'>
                            {maskPassword(row.original.token)}
                        </span>
                        {
                            row?.original?.token !== "" && row?.original?.token !== null && (
                                <>
                                    <span className="px-2">
                                        <CopyToClipboard text={row.original.token} onCopy={handleCopy}>
                                            <i
                                                className="fas fa-copy cursor-pointer"
                                                data-tip="Copied!"
                                                title="Copy token"
                                                data-event="tempclick"
                                                data-text={`${row.original.token}`}
                                                ref={toolRef}
                                            ></i>
                                        </CopyToClipboard>
                                        <ReactTooltip></ReactTooltip>
                                    </span>

                                </>
                            )
                        }
                    </div>
                </div >
            )
        },
    },
    {
        Header: (props: any) => (
            <UserCustomHeader tableProps={props} title='Created At' className='min-w-150px ' />
        ),
        accessor: 'createdAt',
        Cell: ({ row }) => {
            // @ts-ignore: Unreachable code error
            return <div className='d-flex justify-content-start '>
                <span>
                    {moment(row.original.created_at).format('YYYY-MMM-DD')}
                </span>
            </div>
        },
    },
    {
        Header: (props: any) => (
            <UserCustomHeader tableProps={props} title='Actions' className='min-w-150px text-end' />
        ),
        accessor: 'action',
        Cell: ({ row }) => {
            // @ts-ignore: Unreachable code error
            return <UserActionsCell className={'d-flex justify-content-end'} data={row.original
            } />
        },
    },
]

export { usersColumns }