export const initialQueryResponse = {
  refetch: () => {},
  isLoading: false,
  postData: {},
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type Response<T> = {
  data?: any;
  payload: {
    page?: number;
    items_per_page?: number;
    total_records?: number;
    total_pages?: number;
  };
};

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined;
  refetch?: () => void;
  isLoading?: boolean;
  postData?: Object;
};

export interface SchoolBoardMemberListingColumns {
  id?: number;
  state?: string;
  district_school?: any;
  district_name: string;
  board_member?: string;
  contact?: string;
  position?: string;
  term_end_date?: any;
  eAction?: any;
  vImage?:any
}

export type globalDropdown = {
  id: number;
  name: string;
};
