import React, { createContext, useContext, useMemo, useState } from "react";
import {
  ListViewContextProps,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  initialListRequest,
} from "./core/helper";

import { useQueryResponseData } from "./SchoolBoardResponseProvider";


const ListViewContext =
  createContext<ListViewContextProps>(initialListRequest);

const ListViewProvider = ({ children }) => {
  const data = useQueryResponseData()
  const [selected, setSelected] = useState<Array<number>>(initialListRequest.selected)
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])


  return (
    <ListViewContext.Provider
      value={{
        selected,
        setSelected:setSelected,
        onSelect: (id: any) => {
          groupingOnSelect(id, selected, setSelected)
        },
        isAllSelected,
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data)
        },
      }}
    >
      {children}
    </ListViewContext.Provider>
  );
};
const useListRequest = () => useContext(ListViewContext);

export { ListViewProvider, useListRequest };
