export type globalDropdown = {
  id: number;
  name: string;
};

export type DashboardContextType = {
  stateData: any;
  updateStateData: () => {};
};

export interface DashboardColumns {
  state?: string;
  district_name?: string;
  school_board_member?: string;
  no_of_schools?: string;
  address?: string;
  website?: string;
  board_member_website_URLs:string
  url_status:string
  eAction?: any;
  edit?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type Response<T> = {
  data?: any;
  payload: {
    page?: number;
    items_per_page?: number;
    total_records?: number;
    total_pages?: number;
  };
};

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined;
  refetch?: () => void;
  isLoading?: boolean;
  postData?: Object;
};

export const initialQueryResponse = {
  refetch: () => {},
  isLoading: false,
  postData: {},
};
