import React, { useContext, useEffect, useState } from "react";
import {
  PaginationState,
  createResponseContext,
  initialQueryState,
} from "./core/helper";
import { initialQueryResponse } from "./core/_models";
import { useQuery } from "react-query";
import { getUserLists } from "./core/_request";
import { stringifyRequestQuery } from "../Portfolio/core/crud-helper";
import { useQueryRequest } from "./UserRequestProvider";

const QueryResponseContext = createResponseContext<any>(initialQueryResponse);

const UserResponseProvider = ({ children }) => {
  const { state } = useQueryRequest();

  const [postData, setPostData] = useState<any>(state);

  useEffect(() => {
    setPostData(state);
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  var query1 = stringifyRequestQuery(postData);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `users-list-${query1}`,
    () => {
      const list = getUserLists(postData);
      return list;
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  return (
    <QueryResponseContext.Provider
      value={{ response, isLoading: isFetching, refetch }}
    >
      {children}
    </QueryResponseContext.Provider>
  );
};
const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }

  return response?.data?.records || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    total_pages: 0,
    ...initialQueryState,
  };
  const { response } = useQueryResponse();
  if (!response || !response.payload) {
    return defaultPaginationState;
  }
  return response?.payload;
};

const useQueryResponseLoading = () => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

const useQueryRefetch = () => {
  const { refetch } = useQueryResponse();
  return refetch;
};

export {
  UserResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
  useQueryRefetch,
};
