import React, { useState, useEffect } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { KTSVG } from "../../../../../_metronic/helpers";

const CopyToClipBoardActionCell = (text: any) => {
  const [copy, setCopy] = useState(false);

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 300);
    }
  }, [copy]);

  return (
    <div className="d-flex align-items-center email-icon">
      <CopyToClipboard
        text={text?.text}
        onCopy={() => {
          setCopy(true);
        }}
      >
        <span className="cursor-pointer">
          {/* <KTSVG
            path="/media/icons/duotone/General/Envelope.svg"
            className="svg-icon-gray-400 svg-icon-2"
          /> */}
          <KTSVG
            path="/media/logos/icons8-link-96.svg"
            className="svg-icon-gray-400 svg-icon-2"
          />
        </span>
      </CopyToClipboard>
      {copy ? <span style={{ color: "green" }}> &nbsp; Copied.</span> : null}
    </div>
  );
};

export default CopyToClipBoardActionCell;
