// import clsx from 'clsx'
import { FC } from "react";
import { Row } from "react-table";
import { SchoolBoardMemberListingColumns } from "../../core/_models";

type Props = {
  row: Row<SchoolBoardMemberListingColumns>;
};

const getHeader = (cell: any) => {
  return cell?.column?.Header()?.props?.title;
};

const CustomRow: FC<Props> = ({ row }) => {
  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        return (
          <td {...cell.getCellProps()} data-title={getHeader(cell)}>
            <div>{cell.render("Cell")}</div>
          </td>
        );
      })}
    </tr>
  );
};

export { CustomRow };
