import React, { useEffect, useState } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import clsx from "clsx";
import { editProfileData, getProfileData } from "./core/crud-helper/_requests";
import { useQuery } from "react-query";
import * as Yup from "yup";
import { useFormik } from "formik";
import { parseProfileData } from "./helpers";
import Swal from "sweetalert2";
import { Loader } from "../../components";

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  role: "",
  newpassword: "",
  confirmpassword: "",
};

const profileSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  email: Yup.string()
    .email("Wrong email address")
    .required("Email address is required"),
  newpassword: Yup.string()
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  confirmpassword: Yup.string().oneOf(
    [Yup.ref("newpassword"), null],
    "Passwords must match"
  ),
});

const MyProfile = () => {
  const [userProfile, setUserProfile] = useState("");
  const [profile, setProfile] = useState();

  const handleOnProfile = (e) => {
    setProfile(e.target.files[0]);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: profileSchema,
    onSubmit: async (values) => {
      const udatedData = parseProfileData(values);
      const response = await editProfileData(udatedData, profile);
      if (response.status === 200) {
        refetch();
        Swal.fire({
          toast: true,
          title: response.message,
          icon: "success",
          width: "30rem",
          position: "top-right",
          timer: 2500,
          timerProgressBar: true,
          showConfirmButton: false,
          showCancelButton: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      }
    },
  });

  const {
    isLoading,
    refetch,
    data: userData,
  } = useQuery(
    `profile-data`,
    () => {
      const data = getProfileData();
      return data;
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (userData?.data) {
      // eslint-disable-next-line array-callback-return
      Object.keys(userData?.data).map((key) => {
        if (key === "profile") {
          setUserProfile(userData?.data[key]);
        }
        formik.setFieldValue(key, userData?.data[key]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  function getInitials(firstName, lastName) {
    if (firstName && lastName) {
      return `${firstName[0]}${lastName[0]}`;
    }
    if (firstName) {
      return firstName[0];
    }
    if (lastName) {
      return lastName[0];
    }
    return "";
  }

  return (
    <div className="profile-main">
      <PageTitle breadcrumbs={[]}>Profile</PageTitle>
      <div className="profile-root">
        <div className="card">
          <div className="card-body p-4">
            <div className="col-md-12">
              <form
                className="form "
                onSubmit={formik.handleSubmit}
                noValidate
                id="kt_login_profile_form"
              >
                <div className="profile-img d-flex ">
                  <label className="fw-bold fs-6 mb-2">Profile Picture</label>
                  {/* <img src={userProfile} alt="profile-img" /> */}
                  {!userProfile.includes("default.jpg") &&
                  userProfile !== null ? (
                    <img alt="Logo" src={userProfile} />
                  ) : (
                    <div className="symbol-label fs-2 fw-bold text-dark  myprofileavtar uppercase">
                      {getInitials(
                        userData?.data?.first_name,
                        userData?.data?.last_name
                      )}
                    </div>
                  )}

                  <div className="d-flex justify-content-center profile-choose">
                    <input
                      type="file"
                      accept="image"
                      onChange={(e) => handleOnProfile(e)}
                    />
                  </div>
                </div>
                <div className="fv-row row align-items-center mb-2">
                  <div className="col-12 col-md-6   px-2 pb-5 ">
                    <label className="fw-bold fs-6 mb-2 required">
                      First Name
                    </label>
                    <input
                      placeholder="First Name"
                      {...formik.getFieldProps("first_name")}
                      className={clsx(
                        "form-control form-control-lg form-control-solid"
                      )}
                      type="text"
                      name="first_name"
                      value={formik.values.first_name || ""}
                      onChange={formik.handleChange}
                      autoComplete="off"
                    />
                    {formik.touched.first_name && formik.errors.first_name && (
                      <div className="fv-plugins-message-container position-absolute">
                        <span role="alert">{formik.errors.first_name}</span>
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-6   px-2 pb-5 ">
                    <label className="fw-bold fs-6 mb-2 ">Last Name</label>
                    <input
                      placeholder="Last Name"
                      className={clsx(
                        "form-control form-control-lg form-control-solid"
                      )}
                      type="text"
                      name="last_name"
                      value={formik.values.last_name || ""}
                      onChange={formik.handleChange}
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-12 col-md-6   px-2 pb-5 ">
                    <label className="fw-bold fs-6 mb-2 required">
                      Email Address
                    </label>
                    <input
                      placeholder="Email Address"
                      {...formik.getFieldProps("first_name")}
                      className={clsx(
                        "form-control form-control-lg form-control-solid"
                      )}
                      type="email"
                      name="email"
                      value={formik.values.email || ""}
                      onChange={formik.handleChange}
                      autoComplete="off"
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="fv-plugins-message-container position-absolute">
                        <span role="alert">{formik.errors.email}</span>
                      </div>
                    )}
                  </div>

                  <div className="col-12 col-md-6   px-2 pb-5 ">
                    <label className="fw-bold fs-6 mb-2">Password</label>
                    <input
                      placeholder="Password"
                      {...formik.getFieldProps("confirmpassword")}
                      className={clsx(
                        "form-control form-control-lg form-control-solid"
                      )}
                      type="password"
                      name="newpassword"
                      value={formik.values.newpassword || ""}
                      onChange={formik.handleChange}
                      autoComplete="off"
                    />
                    {formik.touched.newpassword &&
                      formik.errors.newpassword && (
                        <div className="fv-plugins-message-container position-absolute">
                          <span role="alert">{formik.errors.newpassword}</span>
                        </div>
                      )}
                  </div>
                  <div className="col-12 col-md-6   px-2 pb-3 ">
                    <label className="fw-bold fs-6 mb-2">
                      Confirm Password
                    </label>
                    <input
                      placeholder="Password"
                      {...formik.getFieldProps("confirmpassword")}
                      className={clsx(
                        "form-control form-control-lg form-control-solid"
                      )}
                      type="password"
                      name="confirmpassword"
                      value={formik.values.confirmpassword || ""}
                      onChange={formik.handleChange}
                      autoComplete="off"
                    />
                    {formik.touched.confirmpassword &&
                      formik.errors.confirmpassword && (
                        <div className="fv-plugins-message-container position-absolute">
                          <span role="alert">
                            {formik.errors.confirmpassword}
                          </span>
                        </div>
                      )}
                  </div>

                  {/* <div className="col-12 col-md-6  px-2 pb-3 ">
                    <label className="fw-bold fs-6 mb-2">Type of User</label>
                    <input
                      type="text"
                      placeholder="Type of User"
                      className={clsx(
                        "form-control form-control-lg form-control-solid"
                      )}
                      name="role"
                      value={formik.values.role}
                      onChange={formik.handleChange}
                      autoComplete="off"
                    />
                  </div> */}
                  <div>
                    <div className="col-12 col-md-6  mt-5 px-2 pb-3 ">
                      <input
                        type="submit"
                        placeholder="Type of User"
                        className={clsx("btn btn-lg btn-primary w-100")}
                        name="usertype"
                        value={"Update Profile"}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {isLoading && <Loader />}
    </div>
  );
};

export default MyProfile;
