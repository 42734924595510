import React, { useRef, useState } from "react";
import { KTCard, KTCardBody, KTSVG } from "../../../_metronic/helpers";
import {
  // useQueryResponseData,
  useQueryResponseLoading,
} from "./DashboardResponseProvider";

import { Loader } from "../../components";
import { DashboardPagination } from "./DashboardPagination";
import { DataTable } from "./table/DataTable";
import { exportDashboardList } from "./core/_request";
import { CSVLink } from "react-csv";
import { useQueryRequest } from "./DashboardRequestProvider";
import Swal from "sweetalert2";

const DashboardList = () => {
  // const dashBoardData = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const { state } = useQueryRequest();
  const [exportLoader, setExportLoader] = useState<boolean>(false);

  const [csvData, setCsvData] = useState();

  const csvDownloadRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const headers = [
    { label: "State Code", key: "state_code" },
    { label: "School State", key: "state" },
    { label: "School District", key: "district_name" },
    { label: "Board Member", key: "school_board_member" },
    { label: "No Of Schools", key: "no_of_schools" },
    { label: "Address", key: "address" },
    { label: "Website", key: "website" },
    { label: "Board member Website URLs", key: "board_member_website_URLs" },
  ];

  const handleOnExport = async () => {
    setExportLoader(true);

    const response = await exportDashboardList(state?.filter);
    if (response.status === 200) {
      if (response?.data?.records?.length > 0) {
        setCsvData(response.data.records);
        setTimeout(() => {
          csvDownloadRef?.current?.link?.click();
          setExportLoader(false);
        }, 1000);
      } else {
        setExportLoader(false);
        Swal.fire({
          toast: true,
          title: "No record available to export",
          icon: "success",
          width: "30rem",
          position: "top-right",
          timer: 2500,
          timerProgressBar: true,
          showConfirmButton: false,
          showCancelButton: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      }
    } else {
      setExportLoader(false);
    }
  };
  return (
    <div>
      <div className="content flex-row-fluid">
        <KTCard className="my-7">
          <KTCardBody className="p-0 p-md-7">
            <div
              className={`col-md-12 text-center pt-5 pb-10 d-flex justify-content-end`}
            >
              {csvData && (
                <CSVLink
                  headers={headers}
                  data={csvData}
                  filename="School_Dashboard_List.csv"
                  // className="hidden"
                  ref={csvDownloadRef}
                  target="_blank"
                />
              )}

              <button
                type="button"
                className="btn btn-primary btn-sm me-3"
                data-kt-menu-dismiss="true"
                data-kt-user-table-filter="filter"
                onClick={handleOnExport}
              >
                <KTSVG
                  path="/media/AFP-Icons/download-solid.svg"
                  className="svg-icon-primary mb-2"
                />
                <span className="indicator-label">Export All</span>
              </button>

              {/* <button
                type="submit"
                className="btn btn-primary btn-sm me-3"
                data-kt-users-modal-action="submit"
              >
                <span className="indicator-label">Select All</span>
              </button>
              <button
                type="submit"
                className="btn btn-primary btn-sm me-3"
                data-kt-users-modal-action="submit"
              >
                <span className="indicator-label">Deselect All</span>
              </button> */}
            </div>
            <DataTable />
            {isLoading && <Loader />}
            <DashboardPagination />
          </KTCardBody>
        </KTCard>
      </div>
      {exportLoader && <Loader />}
    </div>
  );
};

export default DashboardList;
