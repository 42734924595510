import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import * as auth from "./redux/AuthRedux";
import Cookies from "js-cookie";

export function Logout() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(auth.actions.logout());
    Cookies.remove("userAuth");
  }, [dispatch]);

  return (
    <>
      <Navigate to="/login" replace={true} />
    </>
  );
}
