/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
    createResponseContext,
    initialQueryResponse,
    QUERIES,
    stringifyRequestQuery,
} from '../../../../_metronic/helpers'
import { useQueryRequest } from './QueryRequestProvider'
import { getApiKeyList } from './_request'

const QueryResponseContext = createResponseContext<any>(initialQueryResponse)

const QueryResponseProvider: FC = ({ children }) => {
    const { state } = useQueryRequest();
    const [postData, setPostData] = useState<any>(state)
    const updatedPostData = useMemo(() => (state), [state]);

    useEffect(() => {
        let query = stringifyRequestQuery(postData);
        var updatedQuery = stringifyRequestQuery(updatedPostData);
        if (query !== updatedQuery) {
            setPostData(updatedPostData)
        } else {
            refetch();
        }
    }, [updatedPostData])

    var query1 = stringifyRequestQuery(postData);

    const {
        isFetching,
        refetch,
        data: response,
    } = useQuery(
        `${QUERIES.APIKEYS}-${query1}`,
        () => getApiKeyList()
        ,
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
    )

    return (
        <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, postData }}>
            {children}
        </QueryResponseContext.Provider>
    )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
    const { response } = useQueryResponse()
    if (!response) {
        return []
    }

    return response?.data || []
}

const useQueryAllResponseData = () => {
    const { response } = useQueryResponse()
    if (!response) {
        return []
    }

    return response || []
}

const useQueryResponseLoading = (): boolean => {
    const { isLoading } = useQueryResponse()
    return isLoading
}

export {
    QueryResponseProvider,
    useQueryResponse,
    useQueryResponseData,
    useQueryResponseLoading,
    useQueryAllResponseData
}
