import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { UserModel } from "../models/UserModel";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  Login: "[Login] Action",
  LoginUsingToken: "[Login Using Token] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  SetFilterPortFolioData: "[Set Filter PortFolio Data]",
};

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  data: undefined,
};

export interface IAuthState {
  user?: UserModel;
  accessToken?: string;

  data?: any;
}

export const reducer = persistReducer(
  {
    storage,
    key: "user",
    whitelist: ["user", "loginUsingToken", "accessToken"],
  },
  (
    state: IAuthState = initialAuthState,
    action: ActionWithPayload<IAuthState>
  ) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.data.access_token;
        const userData = action.payload?.data;
        return {
          accessToken,
          loginUsingToken: false,
          user: userData,
        };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }
      default:
        return state;
    }
  }
);

export const actions = {
  login: (data: string) => ({
    type: actionTypes.Login,
    payload: { data },
  }),

  logout: () => ({ type: actionTypes.Logout }),

  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),

  fulfillUser: (user: UserModel) => ({
    type: actionTypes.UserLoaded,
    payload: { user },
  }),
};

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  //   yield put(actions.requestUser());
  // });
}
