export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = "application/json";
  axios.defaults.headers.ContentType = "application/json";
  axios.defaults.headers.withCredentials = true;

  // Add request interceptors
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: { accessToken },
      } = store.getState();

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response: any) => {
      return response;
    },
    (err: any) => {
      if (err.response.status === 401) {
        localStorage.clear();
      }
      return Promise.reject(err);
    }
  );
}
