/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { Button, Modal } from "react-bootstrap";
import "react-datetime/css/react-datetime.css";
import {
  createBoardMember,
  getDistrictList,
  getStateList,
  updateBoardMember,
} from "./core/_request";
import Swal from "sweetalert2";
import { useQueryRefetch } from "./SchoolBoardResponseProvider";
import { Loader, SelectSearchField } from "../../components";
import { useQueryRequest } from "./SchoolBoardRequestProvider";
import { globalDropdown } from "./core/_models";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import { createOption } from "./core/helper";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";


const initialValues = {
  board_member: "",
  position: "",
  contact: "",
  term_end_date: "",
};

const schoolBoardMemberSchema = Yup.object().shape({
  board_member: Yup.string().required("Member name is required"),
  position: Yup.string().required("Postion  is required"),
  contact: Yup.string().email("Wrong email address"),
  term_end_date: Yup.string(),
});

const SchoolBoardModel = ({ open, onClose, editData }: any) => {
  const [searchParams] = useSearchParams();

  let queryState: any = {};
  if(searchParams.has("state_id") !== undefined && searchParams.get("state_id") !== null){
    queryState.state_id = searchParams.get("state_id")
  }
  if(searchParams.has("district_id") !== undefined && searchParams.get("district_id") !== null){ 
    queryState.district_id = searchParams.get("district_id")
  }

  const [stateData, setStateData] = useState<globalDropdown[]>([]);
  const [districtData, setDistrictdata] = useState<globalDropdown[]>([]);
  const [stateValue, setStateValue] = useState<any>({
    stateData: "",
    districtData: "",
  });
  const [districtLoading, setDistrictLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<any>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const refetch = useQueryRefetch();

  const userData: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );

  const handleOnChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    setStateValue({ ...stateValue, [name]: value });

    if (name === "stateData") {
      setStateValue((prevSelectedOptions) => ({
        ...prevSelectedOptions,
        districtData: null,
      }));
      setDistrictLoading(true);
      getDistrictList({ id: value.id })
        .then((response) => {
          if (response.status === 200) {
            const stateOption = response?.data?.map((obj) =>
              createOption(obj.id, obj.district)
            );

            setDistrictdata(stateOption);
            setDistrictLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: schoolBoardMemberSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsSubmitting(true);
      if (stateValue.districtData === null || stateValue.districtData === "") {
        setHasError(true);
        setIsSubmitting(false);
        return 0;
      }
      setTimeout(async () => {
        if (editData) {
          var response = await updateBoardMember({
            ...values,
            id: editData?.original?.id,
            district_id: editData?.original.district_id,
          });
        } else {
          const postData = {
            ...values,
            state_id: stateValue?.stateData?.id || stateData?.[0]?.id,
            district_id: stateValue?.districtData?.id || districtData?.[0]?.id,
          };

          // eslint-disable-next-line @typescript-eslint/no-redeclare
          var response = await createBoardMember(postData);
        }
        if (response.status === 200) {
          Swal.fire({
            toast: true,
            title: response.message,
            icon: "success",
            width: "30rem",
            position: "top-right",
            timer: 2500,
            timerProgressBar: true,
            showConfirmButton: false,
            showCancelButton: false,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          setIsSubmitting(false);
          refetch?.();
          handleOnClose();
          resetForm();
        }
      }, 500);
    },
  });

  useEffect(() => {
    if (editData) {
      formik.setFieldValue("board_member", editData?.original?.board_member);
      formik.setFieldValue("position", editData?.original?.position);
      formik.setFieldValue("contact", editData?.original?.contact);
      formik.setFieldValue("term_end_date", editData?.original?.term_end_date);
      // setTermDate(editData?.original?.term_end_date);
      setStateValue({
        ...stateValue,
        stateData: {
          id: editData?.original?.district_school?.state?.id,
          name: editData?.original?.district_school?.state?.value,
        },
        districtData: {
          id: editData?.original?.district_school?.district_id,
          name: editData?.original?.district_school?.district_name,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  const fetchState = () => {
    getStateList({ user_id: userData?.id })
      .then((response) => {
        if (response.status === 200) {
          const stateOption = response?.data?.map((obj) =>
            createOption(obj.id, obj.state)
          );
          setStateData(stateOption);
          if (response?.data?.length > 0) {
            let state = stateOption.filter((state: any) => state.id.toString() === queryState?.state_id.toString())[0];
            
            setStateValue({
              ...stateValue,
              stateData: state,

            });
            
            getDistrictList({
              id: queryState?.state_id || stateOption?.[0]?.id,
            })
              .then((response) => {
                if (response.status === 200) {
                  const distOption = response?.data?.map((obj) =>
                    createOption(obj.id, obj.district)
                  );
                  
                  let district = distOption.filter((district: any) => district.id.toString() === queryState?.district_id.toString())[0];

                  setStateValue({
                    ...stateValue,
                    districtData: district,
                  });

                  setDistrictdata(distOption);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (open === true) {
      fetchState();
    }
  }, [open]);

  const memoziedDistrict = useMemo(() => {
    return districtData;
  }, [districtData]);

  useEffect(() => {
    if (editData === undefined) {

      let state = stateData.filter((state: any) => state.id.toString() === queryState?.state_id.toString())[0];
      let district = districtData.filter((district: any) => district.id.toString() === queryState?.district_id.toString())[0];
      setStateValue({
        stateData: state ?? stateData?.[0],
        districtData: district ?? districtData?.[0],
      });
    }
  }, [editData, stateData, districtData]);

  useEffect(() => {
    if (stateValue.districtData) {
      setHasError(false);
    }
  }, [stateValue]);

  const handleOnClose = () => {
    formik.setFieldValue("board_member", "");
    formik.setFieldValue("position", "");
    formik.setFieldValue("contact", "");
    formik.setFieldValue("term_end_date", "");
    onClose();
  };

  return (
    <div>
      <Modal
        show={open}
        onHide={handleOnClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <form
          className="form "
          onSubmit={formik.handleSubmit}
          noValidate
          id="kt_login_profile_form"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {editData ? "Update" : "Add"} School Board Member
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="fv-row row align-items-center mb-2">
              <div className="col-12 col-md-6  px-2 pb-5 ">
                <label className="fw-bold fs-6 mb-2 required">State</label>
                <SelectSearchField
                  value={stateValue.stateData || stateData?.[0]}
                  options={stateData}
                  isSearchable={true}
                  name="stateData"
                  isLoading={false}
                  loadingMessage="Featching State"
                  noOptionsMessage="State are not available."
                  placeholder="Select State"
                  // value={formik.values.state}
                  handleOnChange={handleOnChange}
                  // isDisabled={editData ? true : false}
                  isClearable={false}
                  // multiple={true}
                />
                {/* {formik.touched.state && formik.errors.state && (
                  <div className="fv-plugins-message-container">
                    <span role="alert">{formik.errors.state}</span>
                  </div>
                )} */}
              </div>
              <div className="col-12 col-md-6  px-2 pb-5 ">
                <label className="fw-bold fs-6 mb-2 required">
                  School District
                </label>
                <SelectSearchField
                  value={stateValue.districtData}
                  options={memoziedDistrict}
                  isSearchable={true}
                  // {...formik.getFieldProps("district")}
                  name="districtData"
                  isLoading={districtLoading}
                  loadingMessage="Featching District"
                  noOptionsMessage="Please Select State"
                  placeholder="Select District"
                  handleOnChange={handleOnChange}
                  // isDisabled={editData ? true : false}
                  isClearable={false}
                  // value={formik.values.district}
                  // handleOnChange={formik.handleChange}
                  //   multiple={true}
                />
                {hasError && (
                  <div className="fv-plugins-message-container position-absolute">
                    <span role="alert">District is required</span>
                  </div>
                )}
              </div>
              <div className="col-12 col-md-6  px-2 pb-5 ">
                <label className="fw-bold fs-6 mb-2 required">
                  Board Member
                </label>
                <input
                  placeholder="Enter Board Member"
                  {...formik.getFieldProps("board_member")}
                  className={clsx("form-control")}
                  type="text"
                  name="board_member"
                  value={formik.values.board_member}
                  onChange={formik.handleChange}
                  autoComplete="off"
                />
                {formik.touched.board_member && formik.errors.board_member && (
                  <div className="fv-plugins-message-container position-absolute">
                    <span role="alert">{formik.errors.board_member}</span>
                  </div>
                )}
              </div>
              <div className="col-12 col-md-6  px-2 pb-5 ">
                <label className="fw-bold fs-6 mb-2 required">Position</label>
                <input
                  placeholder="Enter Position"
                  {...formik.getFieldProps("position")}
                  // className={clsx(
                  //   "form-control form-control-lg form-control-solid"
                  // )}
                  className={clsx("form-control")}
                  type="text"
                  name="position"
                  value={formik.values.position}
                  onChange={formik.handleChange}
                  autoComplete="off"
                />
                {formik.touched.position && formik.errors.position && (
                  <div className="fv-plugins-message-container position-absolute">
                    <span role="alert">{formik.errors.position}</span>
                  </div>
                )}
              </div>
              <div className="col-12 col-md-6  px-2 pb-5 ">
                <label className="fw-bold fs-6 mb-2">Term End Date</label>
                {/* <Datetime
                  value={termEndDate}
                  onChange={(e) => setTermDate(e)}
                  closeOnSelect
                  dateFormat="DD/MM/YYYY"
                  className="date-time-picker"
                  timeFormat={false}
                /> */}
                <input
                  placeholder="Enter Term End Date"
                  {...formik.getFieldProps("term_end_date")}
                  className={clsx("form-control")}
                  type="text"
                  name="term_end_date"
                  value={formik.values.term_end_date}
                  onChange={formik.handleChange}
                  autoComplete="off"
                />
                {formik.touched.term_end_date &&
                  formik.errors.term_end_date && (
                    <div className="fv-plugins-message-container position-absolute">
                      <span role="alert">{formik.errors.term_end_date}</span>
                    </div>
                  )}
              </div>
              <div className="col-12 col-md-6  px-2 pb-5 ">
                <label className="fw-bold fs-6 mb-2">Contact</label>
                <input
                  placeholder="Enter Email Address"
                  {...formik.getFieldProps("contact")}
                  // className={clsx(
                  //   "form-control form-control-lg form-control-solid"
                  // )}
                  className={clsx("form-control")}
                  type="email"
                  name="contact"
                  value={formik.values.contact}
                  onChange={formik.handleChange}
                  autoComplete="off"
                />
                {formik.touched.contact && formik.errors.contact && (
                  <div className="fv-plugins-message-container position-absolute">
                    <span role="alert">{formik.errors.contact}</span>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleOnClose}>
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              {editData ? "Update" : "Add"} Member
              {isSubmitting && (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      {isSubmitting && <Loader />}
    </div>
  );
};

export default SchoolBoardModel;
