/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { SelectSearchField } from "../../components";
import { globalDropdown } from "./core/_models";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import { createOption, initialQueryRequest } from "./core/helper";
import { getDistrictList, getStateList } from "./core/_request";
import { useQueryResponse } from "./SchoolBoardResponseProvider";
import { useQueryRequest } from "./SchoolBoardRequestProvider";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";


const SchoolBoardMemberFilter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [stateData, setStateData] = useState<globalDropdown[]>([]);
  const [districtData, setDistrictdata] = useState<globalDropdown[]>([]);
  const [stateValue, setStateValue] = useState<any>({
    stateData: "",
    districtData: "",
  });
  const [initaialLoading, setInitialLoading] = useState(true);
  const [flag, setFlag] = useState<boolean>(false);
  const [locationState, setLocationState] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLoading } = useQueryResponse();
  const { updateState } = useQueryRequest();
  const { boardMemberState, updateBoardMemberState } = useQueryRequest();
  
  let queryState: any = {};
  if(searchParams.has("state_id") !== undefined && searchParams.get("state_id") !== null){
    queryState.state_id = searchParams.get("state_id")
  }
  if(searchParams.has("district_id") !== undefined && searchParams.get("district_id") !== null){ 
    queryState.district_id = searchParams.get("district_id")
  }

  // const loc:any = JSON.parse(queryState)
  const userData: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );

  useEffect(() => {
    if (initaialLoading) {
      setInitialLoading(false);
    }
  }, [initaialLoading]);

  const handleOnChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    // updateBoardMemberState({ ...boardMemberState, [name]: value });
    setFlag(true);

    if (name === "stateData") {
      getDistrictList({ id: value.id })
        .then((response) => {
          if (response.status === 200) {
            const districtOption = response?.data?.map((obj) =>
              createOption(obj.id, obj.district)
            );
            let stateValuetmp = {...stateValue}
            stateValuetmp.stateData = value
            stateValuetmp.districtData = districtOption[0];

            setDistrictdata(districtOption);
            setStateValue(stateValuetmp);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setStateValue({ ...stateValue, [name]: value });
    }
  };

  const fetchState = () => {
    getStateList({
      user_id: userData?.id,
    })
      .then((response) => {
        if (response.status === 200) {
          const stateOption = response?.data?.map((obj) =>
            createOption(obj.id, obj.state)
          );
          setStateData(stateOption);
          if (response?.data?.length > 0) {
            getDistrictList({
              id: queryState.state_id || stateOption?.[0]?.id,
            })
              .then((response) => {
                if (response.status === 200) {
                  const distOption = response?.data?.map((obj) =>
                    createOption(obj.id, obj.district)
                  );

                  setDistrictdata(distOption);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchState();
  }, []);

  // first time page load list api will call

  useEffect(() => {
    if (
      stateData?.length > 0 &&
      districtData?.length > 0 &&
      flag === false &&
      locationState === false
    ) {
      setStateValue( {
        stateData: stateData?.[0],
        districtData: districtData?.[0]
      })
      updateState({
        ...initialQueryRequest.state,
        filter: {
          state: stateData?.[0]?.id,
          district: districtData?.[0]?.id,
        },
      });
    }
  }, [stateData, districtData, flag, locationState]);

  const memoziedDistrict = useMemo(() => {
    return districtData;
  }, [districtData]);

  // when we trigger search button list api will call

  const onSubmit = (event: any) => {
    event?.preventDefault();
    const state = stateValue.stateData
      ? stateValue.stateData?.id
      : stateData?.[0].id;
    const district = stateValue.districtData
      ? stateValue.districtData?.id
      : districtData?.[0].id;

      updateBoardMemberState({
        stateData: stateValue.stateData,
        districtData: stateValue.districtData,
      })
      
    updateState({
      ...initialQueryRequest.state,
      filter: {
        state: state,
        district: district,
      },
    });
    navigate(`${location.pathname}?state_id=${state}&district_id=${district}`);
  };

  // when we navigate from dashboard to school board meber at time list api will call

  useEffect(() => {
    if (queryState.state_id !== undefined && queryState.district_id !== undefined && flag == false) {
      let state = stateData.filter((state) => state.id.toString() === queryState?.state_id.toString());
      let district = districtData.filter((district) => district.id.toString() === queryState?.district_id.toString());

      setLocationState(true);

      let updatedValue = {
        stateData: flag ? stateValue.stateData : state[0],
        districtData: flag ? stateValue.districtData : district[0],
      }

      setStateValue({
        ...stateValue,
        ...updatedValue
      });
      // updateBoardMemberState(updatedValue)
      updateState({
        filter: {
          state: updatedValue?.stateData?.id,
          district: updatedValue?.districtData?.id,
        },
      });
    }
  }, [stateData, districtData]);

  useEffect(() => {
    if (
      stateData?.length > 0 &&
      districtData?.length > 0 &&
      stateValue?.state?.name !== undefined &&
      stateValue?.districtData?.name !== undefined 
    ) {
      if (
        districtData?.find(
          (obj1) => obj1.name === stateValue?.districtData?.name
        )
      ) {
      } else {
        setStateValue((pre) => ({
          ...pre,
          districtData: districtData?.[0],
        }));
        // updateBoardMemberState((pre) => ({
        //   ...pre,
        //   districtData: districtData?.[0],
        // }));
      }
    }
  }, [stateData, districtData, stateValue]);

  return (
    <div>
      <div className="card">
        <div className="card-body p-4">
          <div className="col-md-12">
            <form className="form" noValidate onSubmit={(e) => onSubmit(e)}>
              <div className="fv-row row align-items-center mb-2">
                <div className="col-12 col-md-6 col-lg-3  px-2 pb-3 ">
                  <label className="fw-bold fs-6 mb-2 required">State</label>
                  <SelectSearchField
                    value={stateValue.stateData}
                    options={stateData}
                    isSearchable={true}
                    name="stateData"
                    isLoading={false}
                    loadingMessage="Featching State"
                    noOptionsMessage="State are not available."
                    placeholder="Select State"
                    handleOnChange={handleOnChange}
                    isClearable={false}
                    // multiple={true}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-3  px-2 pb-3 ">
                  <label className="fw-bold fs-6 mb-2 required">
                    School District
                  </label>
                  <SelectSearchField
                    value={stateValue.districtData}
                    options={memoziedDistrict}
                    isSearchable={true}
                    name="districtData"
                    isLoading={false}
                    loadingMessage="Featching District"
                    noOptionsMessage="Please Select State"
                    placeholder="Select District"
                    handleOnChange={handleOnChange}
                    isClearable={false}
                    //   multiple={true}
                  />
                </div>

                {/* Submission Button */}
                <div
                  className={`col-md-12 text-center pt-5 px-2 d-flex justify-content-end`}
                >
                  {/* <button
                    disabled={isLoading && !initaialLoading}
                    type="button"
                    onClick={() => resetFilter()}
                    className="btn btn-secondary btn-sm me-3"
                    data-kt-menu-dismiss="true"
                    data-kt-user-table-filter="filter"
                  >
                    <span className="indicator-label">Reset</span>
                  </button> */}
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm me-3"
                    data-kt-users-modal-action="submit"
                    disabled={isLoading && !initaialLoading}
                    onClick={(e) => {
                      onSubmit(e);
                    }}
                  >
                    <span className="indicator-label">Search</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolBoardMemberFilter;
