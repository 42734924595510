import { createContext } from "react";
import { QueryResponseContextProps } from "./_models";

export type UserQueryRequestContextProps = {
  state: QueryState;
  updateState: (updates: Partial<QueryState>) => void;
};

export const initialQueryState: QueryState = {
  page: 1,
  limit: 10,
};

export type SortState = {
  sort?: string;
  order?: "asc" | "desc";
};

export const initialQueryRequest: UserQueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
};

export type QueryState = PaginationState & SortState;
//   FilterState &
//   SearchState;

export type PaginationState = {
  page: number;
  // limit: 10 | 30 | 50 | 100;
  limit: 10 | 30 | 50 | 100;

  // total?: number;
  total_records?: number;
  total_pages?: number;
};

export function createResponseContext<T>(
  initialState: QueryResponseContextProps<T>
) {
  return createContext(initialState);
}

export function createOption(id, name) {
  return {
    id,
    name,
  };
}
