import React from "react";
import { KTCard, KTCardBody } from "../../../_metronic/helpers";
import { UserTable } from "./table/UserTable";
import { UserListingPagination } from "./UserListingPagination";

const UserListing = () => {
  return (
    <div>
      <div className="content flex-row-fluid">
        <KTCard className="">
          <KTCardBody className="p-0 p-md-7">
            <UserTable />
            <UserListingPagination />
          </KTCardBody>
        </KTCard>
      </div>
    </div>
  );
};

export default UserListing;
