export type userState = {
  page?: number;
  limit?: number;
  sort?: string;
  order?: string;
};

export interface useListingColumns {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  eAction: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type Response<T> = {
  data?: any;
  payload: {
    page?: number;
    items_per_page?: number;
    total_records?: number;
    total_pages?: number;
  };
};

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined;
  refetch?: () => void;
  isLoading?: boolean;
  postData?: Object;
};

export const initialQueryResponse = {
  refetch: () => {},
  isLoading: false,
  postData: {},
};

export type globalDropdown = {
  id: number;
  name: string;
};
