import React from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import Swal from "sweetalert2";
import { userDelete } from "../../core/_request";
import { useQueryRefetch } from "../../UserResponseProvider";
import { useNavigate } from "react-router-dom";

const ColumnActionCell = ({ row }: any) => {
  const refetch = useQueryRefetch();
  const navigate = useNavigate();

  const handleOnDelete = (id: any) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then(function (result) {
      if (result.value) {
        userDelete({ id: id });
        refetch?.();
        Swal.fire("Deleted!", "User Deleted Successfully", "success");
      }
    });
  };

  return (
    <div>
      <div className="d-flex gap-3">
        <div
          className="symbol-label bg-light p-1 border-radius-4 users-action"
          onClick={() =>
            navigate(`edituser/${row?.original?.id}`, {
              state: {
                id: row?.original?.id,
                first_name: row?.original?.first_name,
                last_name: row?.original?.last_name,
                email: row?.original?.email,
              },
            })
          }
        >
          <KTSVG
            path="/media/AFP-Icons/edit.svg"
            className="svg-icon-1 svg-icon-primary mb-2"
          />
        </div>
        <div
          className="symbol-label bg-light p-1 users-action"
          onClick={() => handleOnDelete(row?.original?.id)}
        >
          <KTSVG
            path="/media/AFP-Icons/delete.svg"
            className="svg-icon-1 svg-icon-primary mb-2"
          />
        </div>
      </div>
    </div>
  );
};

export default ColumnActionCell;
