/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as auth from "../redux/AuthRedux";
import { login } from "../redux/AuthCRUD";
import Cookies from "js-cookie";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email address")
    .required("Email address is required"),
  password: Yup.string().required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [message, setMessage] = useState<string>("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [rememberMe, setRememberMe] = useState<any>(false);
  const dispatch = useDispatch();

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  let location = useLocation();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      login(values.email, values.password)
        .then(({ data }) => {
          if (data.status === 200) {
            setTimeout(() => {
              setLoading(false);
              if (rememberMe === true) {
                Cookies.set("userAuth", data.data.access_token, {
                  expires: new Date(
                    new Date().getTime() + 30 * 24 * 60 * 60 * 1000
                  ),
                });
                dispatch(auth.actions.login(data.data));
              } else {
                Cookies.set("userAuth", data.data.access_token, {
                  expires: new Date(
                    new Date().getTime() + 1 * 24 * 60 * 60 * 1000
                  ),
                });
                dispatch(auth.actions.login(data.data));
              }

              // dispatch(auth.actions.login(data.data));
            }, 1000);
          } else {
            setMessage(data.message);
            setHasErrors(true);
            setSubmitting(false);
            setLoading(false);
          }
        })
        .catch((error) => {
          setHasErrors(true);
          setLoading(false);
          setSubmitting(false);
          setMessage(error?.response?.data?.message);
        });
    },
  });

  useEffect(() => {
    if (location.state !== undefined && location.state !== null) {
      if (
        location.state.success !== undefined &&
        location.state.success !== ""
      ) {
        setMessage(location.state.success);
        setHasErrors(false);
      } else if (
        location.state.error !== undefined &&
        location.state.error !== ""
      ) {
        setMessage(location.state.error);
        setHasErrors(true);
      }
    }
  }, [location.state]);

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">Login</h1>
      </div>

      {hasErrors === true && message !== "" && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{message}</div>
        </div>
      )}

      {hasErrors === false && message !== "" && (
        <div className="mb-10 bg-light-info p-8 rounded">
          <div className="text-info">{message}</div>
        </div>
      )}

      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bolder text-dark required">
          Username
        </label>
        <input
          placeholder="Email Address"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control form-control-lg form-control-solid"
            /* {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            } */
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.email}</span>
          </div>
        )}
      </div>

      <div className="fv-row mb-5">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            <label className="form-label fw-bolder text-dark fs-6 mb-0 required">
              Password
            </label>
          </div>
        </div>
        <div className="eyeWrapper">
          <input
            type={passwordShown ? "text" : "password"}
            placeholder="Password"
            autoComplete="off"
            {...formik.getFieldProps("password")}
            className={clsx("form-control form-control-lg form-control-solid")}
          />

          <i
            className={
              passwordShown
                ? "fa fa-solid fa-eye cursor-pointer"
                : "fa fa-solid fa-eye-slash cursor-pointer"
            }
            onClick={togglePasswordVisiblity}
          ></i>
        </div>

        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className="fv-row mb-10 rember-forgotpassword-root">
        <div className="d-flex remeber-root">
          <input
            id="rember_me"
            type="checkbox"
            value={rememberMe}
            onClick={() => setRememberMe(!rememberMe)}
          />
          <label htmlFor={"rember_me"}>Remember Me</label>
        </div>
        <div className="d-flex justify-content-end ">
          <Link to="/forgot-password" className="link-primary fs-6 fw-bolder ">
            Forgot Password ?
          </Link>
        </div>
      </div>

      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary w-100 "
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Log In</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
}
