import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import clsx from "clsx";
import * as Yup from "yup";

import { updateDashboardData } from "../../core/_request";
import { useQueryResponseRefetch } from "../../DashboardResponseProvider";
import { useNavigate } from "react-router-dom";

const initialValues = {
  state: "",
  district_name: "",
  no_of_schools: "",
  address: "",
  Website: "",
  board_member_website_URLs: ""
};

const dashboardValidationSchema = Yup.object().shape({
  no_of_schools: Yup.number().min(1).positive("Please enter positive value "),
  board_member_website_URLs:  Yup.string()
  .test('check-urls', "Please enter valid url(s)", (value: any, ctx: any) => {
    if(!value){
      return true
    }
    
    let websiteurls = value?.split(",")
    function isValidURL(url) {
      // Regular expression to match URLs starting with "http" or "https"
      const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
      return urlPattern.test(url);
    }
    let validate = websiteurls?.every((url:any,urlIndex:any)=>{
      return isValidURL(url.trim())
    })
    
    return validate || value?.toLowerCase().trim() == "not found"
  }),
});
  
const ColumnActionCell = ({ row }: any) => {
  const refetch = useQueryResponseRefetch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    validationSchema: dashboardValidationSchema,
    onSubmit: async (values) => {
      const updateData: any = {
        state_id: row?.original?.state_id,
        district_id: row?.original?.id,
        district: values?.district_name,
        no_of_schools: values?.no_of_schools,
        address: values?.address,
        website: values?.Website,
        board_member_website_URLs: values?.board_member_website_URLs
      };
      const response = await updateDashboardData(updateData);
      if (response.status === 200) {
        Swal.fire({
          toast: true,
          title: "Dashboard Data updated succesfully",
          icon: "success",
          width: "30rem",
          position: "top-right",
          timer: 2500,
          timerProgressBar: true,
          showConfirmButton: false,
          showCancelButton: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      }
      refetch?.();
      handleClose();
    },
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (row) {
      formik.setFieldValue("state", row?.original?.state);
      formik.setFieldValue("district_name", row?.original?.district_name);
      formik.setFieldValue("no_of_schools", row?.original?.no_of_schools);
      formik.setFieldValue("address", row?.original?.address);
      formik.setFieldValue("Website", row?.original?.website);
      formik.setFieldValue("board_member_website_URLs", row?.original?.board_member_website_URLs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  return (
    <div>
      {" "}
      <div className="d-flex gap-3">
        <div className="">
          {/* <KTSVG
            path="/media/AFP-Icons/edit.svg"
            className="svg-icon-1 svg-icon-primary mb-2"
          /> */}
          {Number(row?.original?.edit) === 1 && (
            <button
              type="submit"
              className="btn btn-primary btn-sm me-3"
              data-kt-users-modal-action="submit"
              onClick={handleShow}
              disabled={show}
            >
              <span className="indicator-label">Edit</span>
            </button>
          )}
        </div>
        <div className="">
          {/* <KTSVG
            path="/media/AFP-Icons/edit.svg"
            className="svg-icon-1 svg-icon-primary mb-2"
          /> */}

          <button
            type="submit"
            className="btn btn-primary btn-sm me-3"
            data-kt-users-modal-action="submit"
            onClick={() =>
              navigate("/schoolboardmember", {
                state: {
                  state: {
                    id: row?.original?.state_id,
                    name: row?.original?.state,
                  },
                  district: {
                    id: row?.original?.id,
                    name: row?.original?.district_name,
                  },
                  modelOpen: true,
                },
              })
            }
          >
            <span className="indicator-label">Add Board Member</span>
          </button>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={"static"}
      >
        <form
          className="form "
          onSubmit={formik.handleSubmit}
          noValidate
          id="kt_login_profile_form"
        >
          <Modal.Header closeButton>
            <Modal.Title>Dashboard Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="fv-row row align-items-center mb-2">
              <div className="col-12 col-md-6 col-lg-4 px-2 pb-3 ">
                <label className="fw-bold fs-6 mb-2">School State</label>
                <input
                  placeholder="School State"
                  {...formik.getFieldProps("state")}
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                  type="text"
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  autoComplete="off"
                  disabled
                />
                {/* {formik.touched.first_name && formik.errors.first_name && (
                    <div className="fv-plugins-message-container">
                      <span role="alert">{formik.errors.first_name}</span>
                    </div>
                  )} */}
              </div>
              <div className="col-12 col-md-6 col-lg-4 px-2 pb-3 ">
                <label className="fw-bold fs-6 mb-2">School District</label>
                <input
                  placeholder="School District"
                  {...formik.getFieldProps("district_name")}
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                  type="text"
                  name="district_name"
                  value={formik.values.district_name}
                  onChange={formik.handleChange}
                  autoComplete="off"
                  disabled
                />
              </div>
              {/* <div className="col-12 col-md-6 col-lg-4 px-2 pb-3 ">
                <label className="fw-bold fs-6 mb-2">School Board</label>
                <input
                  placeholder="School Board"
                  {...formik.getFieldProps("school_board")}
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                  type="text"
                  name="school_board"
                  value={formik.values.school_board}
                  onChange={formik.handleChange}
                  autoComplete="off"
                />
              </div> */}

              <div className="col-12 col-md-6 col-lg-4 px-2 pb-3 ">
                <label className="fw-bold fs-6 mb-2">No. Of School</label>
                <input
                  placeholder="No. Of School"
                  {...formik.getFieldProps("no_of_schools")}
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                  type="number"
                  name="no_of_schools"
                  value={formik.values.no_of_schools}
                  onChange={formik.handleChange}
                  autoComplete="off"
                />
                {formik.errors.no_of_schools && (
                  <div className="fv-plugins-message-container position-relative">
                    <span role="alert">{formik.errors.no_of_schools}</span>
                  </div>
                )}
              </div>
              <div className="col-12 col-md-6 col-lg-4 px-2 pb-3 ">
                <label className="fw-bold fs-6 mb-2">Address</label>
                <input
                  placeholder="Address"
                  {...formik.getFieldProps("address")}
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                  type="text"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  autoComplete="off"
                />
                {/* {formik.touched.confirmpassword &&
                    formik.errors.confirmpassword && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">
                          {formik.errors.confirmpassword}
                        </span>
                      </div>
                    )} */}
              </div>
              <div className="col-12 col-md-6 col-lg-4 px-2 pb-3 ">
                <label className="fw-bold fs-6 mb-2">Website</label>
                <input
                  placeholder="Website"
                  {...formik.getFieldProps("Website")}
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                  type="text"
                  name="Website"
                  value={formik.values.Website}
                  onChange={formik.handleChange}
                  autoComplete="off"
                />
                {/* {formik.touched.confirmpassword &&
                    formik.errors.confirmpassword && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">
                          {formik.errors.confirmpassword}
                        </span>
                      </div>
                    )} */}
              </div>
            </div>
              <div className="col-12 col-md-6 col-lg-8 px-2 pb-3 ">
                <label className="fw-bold fs-6 mb-2">Board member Website URLs</label>
                <textarea 
                  {...formik.getFieldProps("board_member_website_URLs")}
                  className={clsx(
                    "form-control form-control-lg form-control-solid"
                  )}
                  name="board_member_website_URLs"
                  value={formik.values.board_member_website_URLs}
                  onChange={formik.handleChange}
                  autoComplete="off"
                  rows={4} cols={50}
                ></textarea >
                {formik.touched.board_member_website_URLs &&
                    formik.errors.board_member_website_URLs && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">
                          {formik.errors.board_member_website_URLs}
                        </span>
                      </div>
                    )}
              </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Update Data
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default ColumnActionCell;
