import clsx from "clsx";
import React from "react";

const Table = ({ cols, rows, className }: any) => {
  return (
    <div className={clsx("table-responsive", className)}>
      <table
        id="kt_table_users"
        className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
      >
        <thead>
          <tr className="text-start text-table-color fw-bolder text-uppercase gs-0 fw-bolder fs-6">
            {cols?.map((col, index) => (
              <td key={index} style={{ width: col.width }}>
                <span>{col.colHeader}</span>
              </td>
            ))}
          </tr>
        </thead>
        <tbody className="text-gray-600 fw-bold">
          {rows?.length > 0 ? (
            rows?.map((row, index) => (
              <tr key={index}>
                {Object.keys(row).map((key, idx) => (
                  <td key={idx} className={""}>
                    <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                      {row[key]}
                    </span>
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <>
              <tr>
                <td colSpan={10}>
                  <div className="d-flex text-center w-100 align-content-center justify-content-center">
                    No matching records found
                  </div>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
