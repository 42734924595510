import React, { createContext, useContext, useState } from "react";
import {
  DashboardQueryRequestContextProps,
  QueryState,
  initialQueryRequest,
} from "./core/helper";

const DashboardQueryRequestContext =
  createContext<DashboardQueryRequestContextProps>(initialQueryRequest);

const DashboardRequestProvider = ({ children }) => {
  const [state, setState] = useState<QueryState>(initialQueryRequest.state);

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = { ...state, ...updates } as QueryState;
    setState(updatedState);
  };

  return (
    <DashboardQueryRequestContext.Provider value={{ state, updateState }}>
      {children}
    </DashboardQueryRequestContext.Provider>
  );
};
const useQueryRequest = () => useContext(DashboardQueryRequestContext);

export { DashboardRequestProvider, useQueryRequest };
