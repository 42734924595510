import Select from "react-select";
const customStyle = {
  control: (base: any) => {
    return {
      ...base,
      "&:hover": { borderColor: "#B5B5C3" }, // border style on hover
      border: "1px solid #E4E6EF", // default border color
      boxShadow: "none", // no box-shadow
      cursor: "pointer",
    };
  },

  valueContainer: (base: any) => {
    return {
      ...base,
      maxHeight: "150px",
      overflowY: "auto",
    };
  },
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#A1A5B7",
    };
  },

  dropdownIndicator: (provided, state) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
  }),
};

const SelectSearchField = ({
  value,
  handleOnChange,
  isSearchable,
  options,
  name,
  isLoading,
  loadingMessage,
  noOptionsMessage,
  placeholder,
  selectInputRef,
  multiple = false,
  classNamePrefix,
  id,
  isClearable = true,
  isDisabled = false,
  isOpen = undefined,
  className,
  defaultValue,
  menuPlacement = undefined,
  formatOptionLabel,
}: any) => {
  return (
    <Select
      isMulti={multiple}
      ref={selectInputRef ? selectInputRef : null}
      defaultValue={defaultValue}
      styles={customStyle}
      // value={value || defaultValue}
      value={value}
      placeholder={placeholder}
      onChange={(selectedOption: any) => {
        let event = { target: { name: name, value: selectedOption } };
        handleOnChange(event);
      }}
      isSearchable={isSearchable}
      getOptionLabel={(option: any) => option.name}
      getOptionValue={(option: any) => option.id}
      options={options}
      name={name}
      isLoading={isLoading}
      loadingMessage={() => loadingMessage}
      noOptionsMessage={() => noOptionsMessage}
      isClearable={isClearable}
      classNamePrefix={classNamePrefix}
      id={id}
      isDisabled={isDisabled}
      menuIsOpen={isOpen}
      className={className ? className : ""}
      menuPlacement={menuPlacement ? menuPlacement : undefined}
      formatOptionLabel={formatOptionLabel}
    />
  );
};

export { SelectSearchField, customStyle };
