import clsx from "clsx";
import { FC, PropsWithChildren, useMemo } from "react";
import { HeaderProps } from "react-table";
// import { initialQueryState } from "../../../../../_metronic/helpers";

import { DashboardColumns } from "../../core/_models";
import { useQueryRequest } from "../../DashboardRequestProvider";
import { initialQueryState } from "../../core/helper";

type Props = {
  className?: string;
  title?: string;
  tableProps: PropsWithChildren<HeaderProps<DashboardColumns>>;
  isSortable?: boolean;
};
const CustomHeader: FC<Props> = ({
  className,
  title,
  tableProps,
  isSortable = true,
}) => {
  const id = tableProps.column.id;

  const { state, updateState } = useQueryRequest();
  

  const isSelectedForSorting = useMemo(() => {
    return state.sort && state.sort === id;
  }, [state, id]);

  const order: "asc" | "desc" | undefined = useMemo(() => state.order, [state]);

  const sortColumn = () => {
    // avoid sorting for these columns
    if (id === "actions" || id === "selection") {
      return;
    }

    // avoid sorting for these columns
    if (!isSortable) {
      return;
    }

    if (!isSelectedForSorting) {
      // enable sort asc
      updateState({ sort: id, order: "asc", ...initialQueryState });
      return;
    }

    if (isSelectedForSorting && order !== undefined) {
      if (order === "asc") {
        // enable sort desc
        updateState({ sort: id, order: "desc", ...initialQueryState });
        return;
      }

      // disable sort
      updateState({ sort: undefined, order: undefined, ...initialQueryState });
    }
  };

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
        isSortable && !isSelectedForSorting && `table-default-sort-arrow`,
        isSelectedForSorting && order !== undefined && `table-sort-${order}`,
        "fw-bolder",
        "fs-6"
      )}
      style={{ cursor: isSortable ? "pointer" : "default" }}
      onClick={sortColumn}
    >
      {title}
    </th>
  );
};

export { CustomHeader };
