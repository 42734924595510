/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuComponent } from "../../../assets/ts/components";
import {
  KTSVG,
  // KTSVG,
  toAbsoluteUrl,
} from "../../../helpers";
import { useLayout } from "../../core";
import { Header } from "./Header";
import { DefaultTitle } from "./page-title/DefaultTitle";
import { Topbar } from "./Topbar";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../../setup";

export function HeaderWrapper() {
  const { pathname } = useLocation();
  const { config, classes, attributes } = useLayout();
  const { header } = config;
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    MenuComponent.reinitialization();
  }, [pathname]);
  const splitLocation = pathname.split("/");

  const isAuthorized: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );

  const wrapperRef: any = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        wrapperRef.current !== null &&
        !wrapperRef.current.contains(event.target)
      ) {
        setMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [wrapperRef]);

  return (
    <div
      id="kt_header"
      className={clsx(
        "header",
        classes.header.join(" "),
        "align-items-stretch"
      )}
      ref={wrapperRef}
      // data-kt-sticky='true'
      // data-kt-sticky-name='header'
      // data-kt-sticky-offset="{default: '200px', lg: '300px'}"
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(" "),
          "d-flex align-items-center justify-content-between mobile-menu-root"
        )}
      >
        <div className="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0 m-header-logo">
          <Link to="/">
            <img
              alt="Logo"
              // src={toAbsoluteUrl("/media/logos/white-logo-v3.svg")}
              src={toAbsoluteUrl("/media/AFP-Icons/americafirstpolicy.svg")}
              className="logo-default h-60px"
            />
            <img
              alt="Logo"
              // src={toAbsoluteUrl("/media/logos/white-logo-v3.svg")}
              src={toAbsoluteUrl("/media/AFP-Icons/americafirstpolicy.svg")}
              className="logo-sticky h-60px"
            />
          </Link>
        </div>

        <div
          className={
            menu ? "nav-menu-open topbar-menu-list" : "topbar-menu-list"
          }
        >
          <ul>
            <li
              className={
                splitLocation[1] === "dashboard" || splitLocation[1] === ""
                  ? "active-menu"
                  : ""
              }
            >
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li
              className={
                splitLocation[1] === "schoolboardmember" ? "active-menu" : ""
              }
            >
              <Link to="/schoolboardmember">School Board Members</Link>
            </li>
            {isAuthorized?.role === "Admin" && (
              <li className={splitLocation[1] === "users" ? "active-menu" : ""}>
                <Link to="/users"> Users</Link>
              </li>
            )}
            {/* {isAuthorized?.role === "Admin" && (
              <li
                className={
                  splitLocation[1] === "createuser" ? "active-menu" : ""
                }
              >
                <Link to="/createuser">Create User</Link>
              </li>
            )} */}
          </ul>
        </div>

        <div
          className="mobile-menu-bar"
          onClick={() => {
            setMenu(!menu);
            document.body.classList.toggle("body-menu-open");
          }}
        >
          {!menu ? (
            <KTSVG
              path="/media/AFP-Icons/bars-solid.svg"
              className="svg-icon-1 svg-icon-primary mb-2"
            />
          ) : (
            <KTSVG
              path="/media/AFP-Icons/xmark-solid.svg"
              className="svg-icon-1 svg-icon-primary mb-2"
            />
          )}
        </div>

        {/* begin::Wrapper */}
        <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1 m-topbar">
          {/* begin::Navbar */}

          {header.left === "menu" && (
            <div className="d-flex align-items-stretch" id="kt_header_nav">
              <Header />
            </div>
          )}

          {header.left === "page-title" && (
            <div className="d-flex align-items-center" id="kt_header_nav">
              <DefaultTitle />
            </div>
          )}

          <div className="d-flex align-items-stretch flex-shrink-0">
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  );
}
