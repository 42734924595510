import React, { useRef, useState } from "react";
import { KTCard, KTCardBody, KTSVG } from "../../../_metronic/helpers";
import { SchoolBoardMemberTable } from "./table/SchoolBoardMemberTable";
import { SchoolBoardListingPagination } from "./SchoolBoardListingPagination";
import { deleteMultipleBoardMember, exportSchoolBoardMember } from "./core/_request";
import { useQueryRequest } from "./SchoolBoardRequestProvider";
import { CSVLink } from "react-csv";
import { Loader } from "../../components";
import Swal from "sweetalert2";
import { useQueryRefetch } from "./SchoolBoardResponseProvider";
import { useListRequest } from "./ListViewProvider";

const SchoolBoardMemberList = () => {
  const [csvData, setCsvData] = useState<any>();
  const { boardMemberState } = useQueryRequest();
  const {selected, setSelected} = useListRequest();
  const [exportLoader, setExportLoader] = useState<boolean>(false);
  
  const refetch = useQueryRefetch();


  const csvDownloadRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const headers = [
    { label: "State Code", key: "state_code" },
    { label: "School State", key: "state" },
    { label: "School District", key: "district_name" },
    { label: "Board Member", key: "board_member" },
    { label: "Position", key: "position" },
    { label: "Term End Date", key: "term_end_date" },
    { label: "Contact", key: "contact" },
  ];

  const handleOnExport = async () => {
    setExportLoader(true);
    const parseData = {
      state: boardMemberState?.stateData?.id,
      district: boardMemberState?.districtData?.id,
    };
    const response = await exportSchoolBoardMember(parseData);
    if (response.status === 200) {
      if (response?.data?.rows?.length > 0) {
        const parseData = response?.data?.rows?.map((obj: any) => ({
          state_code:obj?.district_school?.state?.key,
          state: obj?.district_school?.state?.value,
          district_name: obj?.district_school?.district_name,
          board_member: obj?.board_member,
          position: obj?.position,
          term_end_date: obj?.term_end_date,
          contact: obj?.contact,
        }));

        setCsvData(parseData);
        setTimeout(() => {
          csvDownloadRef?.current?.link?.click();
          setExportLoader(false);
        }, 1000);
      } else {
        setExportLoader(false);
        Swal.fire({
          toast: true,
          title: "No record available to export",
          icon: "success",
          width: "30rem",
          position: "top-right",
          timer: 2500,
          timerProgressBar: true,
          showConfirmButton: false,
          showCancelButton: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      }
    } else {
      setExportLoader(false);
    }
  };

  const handleOnDeleteSelected = () => {
    if (selected?.length > 0) {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete selected!",
      }).then(function (result) {
        if (result.value) {
          deleteMultipleBoardMember({ ids: selected }).then((res) => {
            if (res.status === 200) {
              refetch?.();
              setSelected([])
              Swal.fire("Deleted!", res.message, "success");
            }
          });
        }
      });
    }
  };

  return (
    <div>
      <div className="content flex-row-fluid">
        <KTCard className="my-7">
          <KTCardBody className="p-0 p-md-7">

            <div
              className={`col-md-12 text-center pt-5 pb-10 d-flex justify-content-between`}
            >
              <div>
                {
                  selected?.length > 0 ?
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm me-3"
                      data-kt-users-modal-action="submit"
                      onClick={handleOnDeleteSelected}
                    >
                      <span className="indicator-label">Delete Selected</span>
                    </button>
                    : null
                }
              </div>
              {csvData?.length > 0 && (
                <CSVLink
                  headers={headers}
                  data={csvData}
                  filename="School_Board_Member_List.csv"
                  // className="hidden"
                  ref={csvDownloadRef}
                  target="_blank"
                />
              )}

              <button
                type="button"
                className="btn btn-primary btn-sm me-3"
                data-kt-menu-dismiss="true"
                data-kt-user-table-filter="filter"
                onClick={handleOnExport}
              >
                <KTSVG
                  path="/media/AFP-Icons/download-solid.svg"
                  className="svg-icon-primary mb-2"
                />
                <span className="indicator-label">Export All</span>
              </button>

            </div>
            <SchoolBoardMemberTable />
            <SchoolBoardListingPagination />
          </KTCardBody>
        </KTCard>
      </div>
      {exportLoader && <Loader />}
    </div>
  );
};

export default SchoolBoardMemberList;
