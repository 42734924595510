/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Outlet, Route, Routes, Navigate } from "react-router-dom";
import { ResetPassword } from "./components/ResetPassword";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
// import { AuthCheck } from './components/AuthCheck'
import { toAbsoluteUrl } from "../../../_metronic/helpers";
// import CreateUser from "./components/CreateUser";

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  return (
    <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed bg-login-page-color">
      {/* begin::Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        {/* begin::Logo */}
        <a href="#" className="mb-8">
          <img
            alt="Logo"
            className="h-100px logo"
            // src={toAbsoluteUrl("/media/logos/white-logo-v3.svg")}
            src={toAbsoluteUrl("/media/AFP-Icons/americafirstpolicy.svg")}
          />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className="w-lg-400px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto login-form-design">
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      {/* Redirect to Login after not get any route */}
      <Route path="/*" element={<Navigate to="/login" />} />
      <Route path="login" element={<Login />} />
      <Route path="reset-password/:token" element={<ResetPassword />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
    </Route>
  </Routes>
);

export { AuthPage };
