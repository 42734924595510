import React, { useEffect, useState } from "react";
import { SchoolBoardRequestProvider } from "./SchoolBoardRequestProvider";
import { SchoolBoardResponseProvider } from "./SchoolBoardResponseProvider";
import SchoolBoardMemberList from "./SchoolBoardMemberList";
import SchoolBoardMemberFilter from "./SchoolBoardMemberFilter";
import { PageTitle, RightSideContent } from "../../../_metronic/layout/core";
import SchoolBoardModel from "./SchoolBoardModel";
import { useLocation } from "react-router-dom";
import { ListViewProvider } from "./ListViewProvider";

const SchoolBoardMemberMain = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.modelOpen === true) {
      setShow(true);
    }
  }, [location?.state?.modelOpen]);

  return (
    <div>
      <PageTitle breadcrumbs={[]}>School Board Members</PageTitle>

      <RightSideContent>
        <button
          type="button"
          className="btn btn-primary btn-sm me-3"
          data-kt-menu-dismiss="true"
          // data-kt-user-table-filter="filter"
          onClick={handleShow}
        >
          <span className="indicator-label">Add New Board Member</span>
        </button>
      </RightSideContent>

      <SchoolBoardRequestProvider>
        <SchoolBoardResponseProvider>
          <ListViewProvider>
          <SchoolBoardMemberFilter />
          <SchoolBoardMemberList />
          <SchoolBoardModel open={show} onClose={handleClose} />
          </ListViewProvider>
        </SchoolBoardResponseProvider>
      </SchoolBoardRequestProvider>
    </div>
  );
};

export default SchoolBoardMemberMain;
