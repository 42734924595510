import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const getStatesList = (): Promise<any> => {
  return axios.get(`${API_URL}api/states/list`).then((response) => {
    return response.data;
  });
};
const createUser = (data: any): Promise<any> => {
  return axios.post(`${API_URL}api/user/create`, data).then((response) => {
    return response.data;
  });
};

const editUser = (data: any): Promise<any> => {
  return axios.post(`${API_URL}api/user/edit`, data).then((response) => {
    return response.data;
  });
};

export { getStatesList, createUser, editUser };
