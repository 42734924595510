/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { SelectSearchField } from "../../components";
import { globalDropdown } from "./core/_models";
import { getStateList } from "./core/_request";
import { createOption, initialQueryRequest } from "./core/helper";
import { useQueryRequest } from "./DashboardRequestProvider";
import { useQueryResponse } from "./DashboardResponseProvider";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../setup";

const DashboardFilter = () => {
  const userData: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );

  const [stateData, setStateData] = useState<globalDropdown[]>([]);
  // const [districtData, setDistrictdata] = useState<globalDropdown[]>([]);
  const [initaialLoading, setInitialLoading] = useState(true);
  const [stateValue, setStateValue] = useState<any>({
    stateData: "",
    districtData: "",
    schoolboard: "",
  });
  // const [flag, setFlag] = useState<boolean>(false);

  const { isLoading } = useQueryResponse();
  const { updateState } = useQueryRequest();

  const handleOnChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setStateValue({ ...stateValue, [name]: value });
    // setFlag(true);

    // const state = stateValue.stateData
    //   ? stateValue.stateData?.name
    //   : stateData?.[0].name;
    // const district = stateValue.districtData
    //   ? stateValue.districtData?.name
    //   : districtData?.[0].name;

    updateState({
      ...initialQueryRequest.state,
      filter: {
        state: value.id,
        // district: district,
      },
    });
  };

  const onSubmit = (event: any) => {
    // event?.preventDefault();
    // const state = stateValue.stateData
    //   ? stateValue.stateData?.name
    //   : stateData?.[0].name;
    // const district = stateValue.districtData
    //   ? stateValue.districtData?.name
    //   : districtData?.[0].name;
    // updateState({
    //   ...initialQueryRequest.state,
    //   filter: {
    //     state: state,
    //     district: district,
    //   },
    // });
  };

  // const resetFilter = () => {
  //   setStateValue({
  //     stateData: "",
  //     districtData: "",
  //     schoolboard: "",
  //   });
  //   updateState({
  //     filter: {
  //       state: "",
  //       district: "",
  //     },
  //     ...initialQueryRequest.state,
  //   });
  // };

  useEffect(() => {
    if (initaialLoading && !isLoading) {
      setInitialLoading(false);
    }
  }, [isLoading, initaialLoading]);

  useEffect(() => {
    getStateList({ user_id: userData?.id })
      .then((response) => {
        if (response.status === 200) {
          const stateOption = response?.data?.map((obj) =>
            createOption(obj.id, obj.state)
          );
          setStateData(stateOption);

          updateState({
            ...initialQueryRequest.state,
            filter: {
              state: response?.data?.[0].id,
              // district: response?.data?.[0]?.district,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (stateValue?.stateData.id || stateData?.[0]) {
  //     getDistrictList({ id: stateValue?.stateData.id || stateData?.[0]?.id })
  //       .then((response) => {
  //         if (response.status === 200) {
  //           const stateOption = response?.data?.map((obj) =>
  //             createOption(obj.id, obj.district)
  //           );
  //           setDistrictdata(stateOption);
  //           if (response?.data?.length > 0 && flag === false) {
  //             updateState({
  //               ...initialQueryRequest.state,
  //               filter: {
  //                 state: stateData?.[0].name,
  //                 district: response?.data?.[0]?.district,
  //               },
  //             });
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // }, [stateValue, stateData, flag]);

  return (
    <div className="card">
      <div className="card-body p-4">
        <div className="col-md-12">
          <form className="form" noValidate onSubmit={(e) => onSubmit(e)}>
            <div className="fv-row row align-items-center mb-2">
              <div className="col-12 col-md-6 col-lg-3  px-2 pb-3 ">
                <label className="fw-bold fs-6 mb-2 required">State</label>
                <SelectSearchField
                  value={stateValue.stateData || stateData?.[0]}
                  options={stateData}
                  isSearchable={true}
                  // defaultValue={stateData?.[0]}
                  name="stateData"
                  isLoading={false}
                  loadingMessage="Featching State"
                  noOptionsMessage="State are not available."
                  placeholder="Select State"
                  handleOnChange={handleOnChange}
                  isClearable={false}
                  // multiple={true}
                />
              </div>
              {/* <div className="col-12 col-md-6 col-lg-3  px-2 pb-3 ">
                <label className="fw-bold fs-6 mb-2">District</label>
                <SelectSearchField
                  value={stateValue.districtData}
                  options={districtData}
                  defaultValue={districtData?.[0]}
                  isSearchable={true}
                  name="districtData"
                  isLoading={false}
                  loadingMessage="Featching District"
                  noOptionsMessage="Please Select State"
                  placeholder="Select District"
                  isClearable={false}
                  handleOnChange={handleOnChange}
                  //   multiple={true}
                />
              </div> */}
              {/* <div className="col-12 col-md-6 col-lg-3  px-2 pb-3 ">
                <label className="fw-bold fs-6 mb-2">School Board</label>
                <input
                  className="form-control"
                  type="text"
                  name="schoolboard"
                  placeholder="Enter School Board"
                  onChange={handleOnChange}
                  value={stateValue.schoolboard}
                />
              </div> */}

              {/* Submission Button */}
              {/* <div
                className={`col-md-12 text-center pt-5 px-2 d-flex justify-content-end`}
              >
                <button
                  disabled={isLoading && !initaialLoading}
                  type="button"
                  onClick={() => resetFilter()}
                  className="btn btn-secondary btn-sm me-3"
                  data-kt-menu-dismiss="true"
                  data-kt-user-table-filter="filter"
                >
                  <span className="indicator-label">Reset</span>
                </button>
                <button
                  type="submit"
                  className="btn btn-primary btn-sm me-3"
                  data-kt-users-modal-action="submit"
                  disabled={isLoading && !initaialLoading}
                  onClick={(e) => {
                    onSubmit(e);
                  }}
                >
                  <span className="indicator-label">Search</span>
                </button>
              </div> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DashboardFilter;
