import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const getProfileData = (): Promise<any> => {
  return axios.get(`${API_URL}api/get-profile`).then((response) => {
    return response.data;
  });
};

const editProfileData = (postData: any, profile: any): Promise<any> => {
  const formData = new FormData();
  postData.first_name && formData.append("first_name", postData.first_name);
  postData.last_name && formData.append("last_name", postData.last_name);
  postData.email && formData.append("email", postData.email);
  postData.password && formData.append("password", postData.password);
  profile && formData.append("profile", profile);

  return axios.post(`${API_URL}api/edit/profile`, formData).then((response) => {
    return response.data;
  });
};

export { getProfileData, editProfileData };
