import React from "react";
import { Footer } from "./components/Footer";
import { HeaderWrapper } from "./components/header/HeaderWrapper";
import { Toolbar } from "./components/toolbar/Toolbar";
import { ScrollTop } from "./components/ScrollTop";
import { Content } from "./components/Content";
import { MasterInit } from "./MasterInit";
import { PageDataProvider, useLayout } from "./core";
import clsx from "clsx";

const MasterLayout: React.FC = ({ children }) => {
  const { classes } = useLayout();

  return (
    <PageDataProvider>
      <div className="page d-flex flex-row flex-column-fluid">
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <HeaderWrapper />

          <div
            id="kt_content"
            className="content d-flex flex-column flex-column-fluid"
          >
            <Toolbar />
            <div
              className={clsx(
                "d-flex flex-column-fluid align-items-start",
                classes.contentContainer.join(" ")
              )}
              id="kt_post"
              // style={{maxWidth: 'min-content'}}ś
            >
              {/* <PortFolioWrapper /> */}
              {/* <AsideDefault /> */}
              <Content>{children}</Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      <MasterInit />
      <ScrollTop />
    </PageDataProvider>
  );
};

export { MasterLayout };
