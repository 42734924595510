import { FC, useState, createContext, useContext } from 'react'
import {
    QueryState,
    QueryRequestContextProps,
    initialQueryRequest,
} from '../../../../_metronic/helpers'

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)

const QueryRequestProvider: FC = ({ children }) => {

    const [state, setState] = useState<QueryState>(initialQueryRequest.state)

    const updateState = (updates: Partial<any>) => {
        const updatedState = { ...state, ...updates } as any
        setState(updatedState)
    }

    return (
        <QueryRequestContext.Provider value={{ state, updateState }}>
            {children}
        </QueryRequestContext.Provider>
    )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export { QueryRequestProvider, useQueryRequest }
