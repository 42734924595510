/* eslint-disable jsx-a11y/anchor-is-valid */
import ReactPaginate from "react-paginate";
import { useQueryResponsePagination } from "./DashboardResponseProvider";
import { useQueryRequest } from "./DashboardRequestProvider";
import { ScrollTopComponent } from "../../../_metronic/assets/ts/components";

const DashboardPagination = () => {
  const pagination = useQueryResponsePagination();

  //   const isLoading = useQueryResponseLoading();
  const pageSizeList = [30, 50, 100, 200];
  const { updateState, state } = useQueryRequest();

  // scroll page
  const scrollTop = () => {
    ScrollTopComponent.goTop();
  };
  const updatePage = (page: number | null) => {
    if (!page || pagination.page === page) {
      return;
    }
    updateState({ page });
  };

  // Pagination
  const handlePageClick = (data: any) => {
    // Add plus 1 because of index start with 0
    let page = parseInt(data.selected) + 1;
    updatePage(page);

    setTimeout(() => {
      scrollTop();
    }, 0);
  };

  // Change page size
  const handleOnChange = (event: any) => {
    if (event.target.value > 30) {
      updateState({ page: 1, limit: event.target.value });
    } else {
      updateState({ limit: event.target.value });
    }
  };

  
  const totalRecords =
    pagination.total_records !== undefined ? pagination.total_records : 0;

  return (
    <div className="row mt-4">
      <div className="col-sm-12 col-xl-6 d-flex align-items-center justify-content-between flex-column flex-md-row">
        <p>{`Showing ${state?.page} to ${
          state?.page * state.limit >= totalRecords
            ? totalRecords
            : state.page * state.limit
        } of ${pagination.total_records} entries`}</p>
        <p className="d-flex align-items-center">
          <span className="m-1">Show</span>
          <select
            name="limit"
            value={state.limit}
            className="form-select w-auto pt-2 pb-2 cursor-pointer"
            onChange={handleOnChange}
          >
            {pageSizeList.length > 0 &&
              pageSizeList.map((item, pIndex) => {
                
                // if (Math.ceil(totalRecords / 10) * 10 >= 10) {
                return (
                  <option value={item} key={`project-${pIndex}`}>
                    {item}
                  </option>
                );
                // } else if (totalRecords < 10 && pIndex === 0) {
                //     return (
                //         <option value={10} key={`project-${pIndex}-default`}>
                //             10
                //         </option>
                //     )
                // }
              })}
          </select>
          <span className="m-1">Entries</span>
        </p>
      </div>
      <div className="col-sm-12 col-xl-6 d-flex align-items-center justify-content-center justify-content-xl-end">
        <div id="kt_table_users_paginate">
          <ReactPaginate
            previousLabel="< Previous"
            nextLabel="Next >"
            pageCount={pagination?.total_pages || 100}
            onPageChange={handlePageClick}
            // Bootstrap for pagination
            containerClassName="pagination justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
            forcePage={state.page - 1}
          />
        </div>
      </div>
    </div>
  );
};

export { DashboardPagination };
