import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user`;
export const LOGIN_URL = `${API_URL}api/login`;
export const PASSWORD_CHANGE_URL = `${API_URL}api/change-password`;
export const REQUEST_PASSWORD_URL = `${API_URL}api/forgot-password`;
export const VERIFY_TOKEN = `${API_URL}api/verify-token`;

export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  });
}

export function requestPassword(email: string) {
  return axios.post(REQUEST_PASSWORD_URL, {
    email,
  });
}

export function veryToken(data: any) {
  return axios.post(VERIFY_TOKEN, {
    email: data.email,
    token: data.token,
  });
}

export function changePassword(
  email: any,
  password: string,
  confirm_password: string
) {
  return axios.post(PASSWORD_CHANGE_URL, {
    email,
    password,
    confirm_password,
  });
}

export async function getUserByToken(token: string) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bareer ${token}`,
  };
  return axios
    .get<any>(GET_USER_BY_ACCESSTOKEN_URL, { headers: headers })
    .then((response) => response.data);
}
