import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { addEditApiKey } from '../core/_request'
import { useQueryResponse } from '../core/QueryResponseProvider'
import Swal from 'sweetalert2'

type Props = {
    show: boolean;
    setShow: (show: boolean) => void;
    data?: any;
    isEdit?: boolean
}
const AddEditApiKey = ({ show, setShow, data, isEdit }: Props) => {
    const { refetch } = useQueryResponse()
    const [state, setState] = useState<any>({
        apiName: '',
        id: ''
    })
    const [error, setError] = useState<string>('')

    useEffect(() => {
        if (isEdit) {
            const temSateData: any = {}
            temSateData['apiName'] = data?.token_name
            temSateData['id'] = data?.id
            setState(temSateData)
        }
    }, [isEdit, data?.id, data?.token_name])
    const handleClose = () => setShow(false)

    const handleSubmit = (e: any) => {
        e.preventDefault()
        const tempSate = { ...state }

        //add and Edit api name
        addEditApiKey(tempSate).then((response) => {
            if (response.status === 200) {
                setError('')
                setShow(false)
                Swal.fire({
                    toast: true,
                    title: response.message,
                    icon: 'success',
                    width: '30rem',
                    position: 'top-right',
                    timer: 2500,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCancelButton: false,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    },
                }).then((response) => {
                    if (response.isDismissed) {
                        setState({ ...state, apiName: '' })
                        refetch()
                    }
                })
            } else if (response.status === 400) {
                setError(response.message)
            }

        })


    }
    return (
        <>
            <Modal show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                scrollable={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Header className='justify-content-center'>
                    <Modal.Title className='text-center'>
                        Create Api Key
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className='py-4' >
                    <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">Api Name</label>
                        <input
                            placeholder="Api Name"
                            name="apiName"
                            className="form-control mb-3 mb-lg-0"
                            type="text"
                            value={state.apiName}
                            onChange={(e) => {
                                setState({ ...state, apiName: e.target.value })
                                setError('')
                            }}
                        />
                        <div className='fv-plugins-message-container' style={{
                            position: "absolute"
                        }}>
                            <div className='fv-help-block'>
                                {error && <span role='alert'>{error}</span>}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='py-4'>
                    <button
                        onClick={(e) => handleSubmit(e)}
                        className='btn btn-sm btn-primary me-3 mt-5 mb-5'
                    >
                        Submit
                    </button>
                    <button
                        onClick={handleClose}
                        className='btn btn-light me-3'
                    >
                        Close
                    </button>
                </Modal.Footer>
            </Modal>

        </>
    )


}

export default AddEditApiKey
