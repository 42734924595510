import { Column } from "react-table";
import { CustomHeader } from "./CustomHeader";
import { SchoolBoardMemberListingColumns } from "../../core/_models";
import ColumnActionCell from "./ColumnActionCell";
import { UserSelectionHeader } from "./UserSelectionHeader";
import { UserSelectionCell } from "./UserSelectionCell";

const usersColumns: Array<Column<SchoolBoardMemberListingColumns>> = [
  /* {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="School State"
        className="min-w-150px"
      />
    ),
    accessor: "state",
    Cell: ({ row }) => {
      // @ts-ignore: Unreachable code error
      return (
        <div className="row">
          <div className="col-md-12">
            <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
              {row?.original?.district_school?.state?.value}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="School District"
        className="min-w-200px"
      />
    ),
    accessor: "district_name",
    Cell: ({ row }) => {
      // @ts-ignore: Unreachable code error
      return (
        <div className="row">
          <div className="col-md-12">
            <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
            {row?.original?.district_school?.district_name}
            </span>
          </div>
        </div>
      );
    },
  }, */
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    accessor: 'vImage',
    Cell: ({ row }) => (<UserSelectionCell id={row.original.id} />)
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Board Member"
        className="min-w-200px"
      />
    ),
    accessor: "board_member",
    Cell: ({ row }) => {
      // @ts-ignore: Unreachable code error
      return (
        <div className="row">
          <div className="col-md-12">
            <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
              {row?.original?.board_member}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Position"
        className="min-w-150px"
      />
    ),
    accessor: "position",
    Cell: ({ row }) => {
      // @ts-ignore: Unreachable code error
      return (
        <div className="row">
          <div className="col-md-10">
            <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
              {row?.original?.position}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Term End Date  "
        className="min-w-150px"
      />
    ),
    id: "term_end_date",
    Cell: ({ row }) => {
      // @ts-ignore: Unreachable code error
      return (
        <div className="row">
          <div className="col-md-10">
            <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
              {row?.original?.term_end_date || "-"}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Contact"
        className="min-w-200px"
      />
    ),
    accessor: "contact",
    Cell: ({ row }) => {
      return (
        <div className="row">
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-10">
                <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                  {row?.original?.contact || "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    },
  },

  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Action"
        className="min-w-70px"
        isSortable={false}
      />
    ),
    accessor: "eAction",

    Cell: ({ row }) => {
      return (
        <div className="row">
          <div className="col-md-10">
            <ColumnActionCell row={row} />
          </div>
        </div>
      );
    },
  },
];

export { usersColumns };
