import { FC, useState } from "react";
import { KTCard, KTCardBody } from "../../../_metronic/helpers";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import ApikeyTable from "./table/ApikeyTable";
import AddEditApiKey from "./add/AddEditApiKey";
import {
    PageTitle,
    RightSideContent
} from "../../../_metronic/layout/core";

const ApiList: FC = () => {
    const [show, setShow] = useState<boolean>(false)
    return (
        <>
            <PageTitle breadcrumbs={[]}>Api Keys</PageTitle>
            <RightSideContent><button
                className='btn btn-sm btn-primary me-3 mt-5 mb-5'
                onClick={() => setShow(true)}>
                Create Api Key
            </button></RightSideContent>
            <KTCard className="my-7">
                <KTCardBody className="p-0 p-md-7">
                    <AddEditApiKey show={show} setShow={setShow} />
                    <ApikeyTable />
                </KTCardBody>
            </KTCard>
        </>
    );
};

export default function ApikeyMangementWrapper() {
    return (
        <div className="content flex-row-fluid">
            <QueryRequestProvider>
                <QueryResponseProvider>
                    <ApiList />
                </QueryResponseProvider>
            </QueryRequestProvider>
        </div>
    );
}
